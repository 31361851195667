import React from "react";
import AuthNavbar from "./AuthNavbar";
import "../../../../css/portal/auth-layout.css";
const AuthLayout = ({ children }) => {
  return (
    <div className="auth-layout">
      <AuthNavbar />

      <div className="auth-body">
        <div className="auth-content">{children}</div>
        <div className="auth-bg"></div>
      </div>
    </div>
  );
};

export default AuthLayout;
