import {
    ERROR_ADDING_ACTIVITY,
    PUBLISH_ACTIVITY,
    LOAD_ALL_PDF_BOOKS,
    FETCH_ALL_PDF_BOOKS,
    ERROR_LOADING_ALL_PDF_BOOKS,
    LOAD_PDF_BOOK,
    PUBLISH_PDF_BOOK,
    ERROR_ADDING_PDF_BOOK,
    ADD_PDF_BOOK,
    GET_PDF_BOOK_DATA,
    ERROR_LOADING_PDF_BOOK,
    EDIT_PDF_BOOK_DATA,
    ERROR_EDITING_PDF_BOOK,
    EMPTY_PDF_BOOK,
    EMPTY_ALL_PDF_BOOKS,
    DELETE_PDF_BOOK,
    ERROR_DELETING_PDF_BOOK,
    SETTING_PDF_BOOK_STATUS,
    ERROR_SETTING_PDF_BOOK_STATUS,
    ADD_PDF_BOOK_WITH_SENTFROM,
    DELETE_BOOK_PAGE,
    ERROR_DELETING_BOOK_PAGE,
    ADD_BOOK_PAGE,
    ERROR_ADDING_PAGE,
    UPDATE_BOOK_PAGE,
    ERROR_UPDATE_BOOK_PAGE,
    ERROR_ADDING_BOOK_PAGE,
    LOAD_BOOK_PAGE,
    pdf_get_all_parent_error,
    GET_ALL_PARENTS
} from './Types'

import axios, { post, put } from 'axios'
import { saveActivity } from '../Promises/Activity.js';
import firebase from 'firebase/app'
import { savePdfBook } from '../Promises/PdfBook';
export const fetchPdfBooks = () => dispatch => {
    dispatch({
        type: LOAD_ALL_PDF_BOOKS,
        payload: 'Loading'
    })
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          user.getIdToken().then((idToken) => {
            const config = {
                            headers: {
                                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                                'X-Authorization-Firebase': idToken
                            }
                        }

            axios.get('/PdfBook/getAllPdfBookList', config)
                .then((res) => {
                    dispatch({
                        type: FETCH_ALL_PDF_BOOKS,
                        payload: res.data
                    })
                }
                ).catch((Error) => {
                    dispatch({
                        type: ERROR_LOADING_ALL_PDF_BOOKS,
                        payload: Error.message
                    })
                })
          });
        }
      });
}

export const publishPdfBook = (name) => dispatch => {
    dispatch({
        type: LOAD_PDF_BOOK,
        payload: 'Loading'
    })

    saveActivity(name).then((res) => {
        dispatch({
            type: PUBLISH_ACTIVITY,
            payload: res
        })
        savePdfBook(res.id).then((res) => {
            dispatch(
                {
                    type: PUBLISH_PDF_BOOK,
                    payload: res.data
                }
            )
        }).catch((Error) => {
            dispatch(
                {
                    type: ERROR_ADDING_PDF_BOOK,
                    payload: Error.message
                })
        })



    }).catch((Error) => {
        dispatch({
            type: ERROR_ADDING_ACTIVITY,
            payload: Error.message
        })
    });
}

export const addPdfBook = (name, description, image, PublishDate,
    pdfFile, titleAudio,
    bookbutton, isFree, subValueId, featured,bookParent,contentVendorSelectedId,ageFrom,ageTo) => dispatch => {

        if (description === null &&
            description === undefined) {
            description = '';
        }

        const pdfBook = {
            name: name,
            description: description,
            image: image,
            PublishDate: PublishDate,
            pdfFile: pdfFile,
            bookbutton: bookbutton,
            isFree: isFree,
            subValueId: subValueId,
            titleAudio: titleAudio,
            featured: featured,
            bookParent:bookParent,
            contentVendorSelectedId:contentVendorSelectedId,
            ageFrom:ageFrom,
            ageTo:ageTo,
        }

        dispatch({
            type: ADD_PDF_BOOK,
            payload: pdfBook
        })
        
    }

export const addPdfBookItemWithSentFrom = (item, sentFrom) => dispatch => {
    const pdfBook = {
        item: item,
        sentFrom: sentFrom
    }
    dispatch({
        type: ADD_PDF_BOOK_WITH_SENTFROM,
        payload: pdfBook
    })
}

export const getPdfBookData = (id) => (dispatch) => {
    dispatch({
        type: LOAD_PDF_BOOK,
        payload: 'Loading'
    })
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          user.getIdToken().then((idToken) => {
            const formData = new FormData();
            formData.append('ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': idToken
                },
                params: {
                    'ID': id
                }
            }

            axios.get('/PdfBook/getPdfBookDetails', config)
                .then((res) => {
                    dispatch(
                        {
                            type: GET_PDF_BOOK_DATA,
                            payload: res.data
                        }
                    )
                }
                ).catch((Error) => {
                    dispatch({
                        type: ERROR_LOADING_PDF_BOOK,
                        payload: Error.message
                    })
                })
          });
        }
      });
}

export const updatePdfBook = (id, name, description, Image,
    PublishDate,titleAudio, pdfFile, activityId,
     paymentAva, subValueId, featured,bookParent,contentVendorSelectedId,ageFrom,ageTo,selected_pdfParent_id) => {

	//console.log(' selected_pdfParent_id  in pdfAction is : '+selected_pdfParent_id);

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_PDF_BOOK,
                payload: 'Loading'
            })


            if (description === null &&
                description === undefined) {
                description = '';
            }

            let isFree = ''
            if (paymentAva === 'paid') {
                isFree = 0
            }
            else if (paymentAva === 'free') {
                isFree = 1
            }

            let updatedSubValueId
            if (subValueId === '' ||
                subValueId === undefined ||
                subValueId === null) {
                updatedSubValueId = 0
            } else {
                updatedSubValueId = subValueId
            }


            let tempTitleAudio = titleAudio;
            if (titleAudio == null ||
                titleAudio == undefined ||
                titleAudio == '') {

                tempTitleAudio = ''
            }

            let tempPdfFile = pdfFile;
            if (pdfFile == null ||
                pdfFile == undefined ||
                pdfFile == '') {

                tempPdfFile = ''
            }

            let status = '';
            if (name === '' || name === null || name === undefined
                || Image === '' || Image === null || Image === undefined
                || PublishDate === '' || PublishDate === null
                || tempPdfFile === '' || tempPdfFile === null
                || tempPdfFile === undefined) {
                status = 'SUSPENDED'
            }
            else {
                status = 'DEFAULT'
            }

            const url = "/PdfBook/updatePdfBook";
            const formData = new FormData();
            formData.append('ID', id);
            formData.append('Name', name);
            formData.append('Description', description);
            formData.append('Image', Image);
            formData.append('Status', status);
            formData.append('Publish_Date', PublishDate);

            formData.append('Voice', tempTitleAudio);
            formData.append('Pdf_Url', tempPdfFile);

            formData.append('Activity_ID', activityId.id);
            formData.append('Is_free', isFree);
            formData.append('Content_Sub_Value_Id', updatedSubValueId);
            formData.append('featured', featured);
            formData.append('book_Parent_Id', bookParent);
            formData.append('contentVendorSelectedId', contentVendorSelectedId);
            formData.append('ageFrom', ageFrom);
            formData.append('ageTo', ageTo);
            formData.append('newParentId', selected_pdfParent_id);
            
            

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: EDIT_PDF_BOOK_DATA,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_EDITING_PDF_BOOK,
                    payload: Error.message
                })
            })
        })
    }
}

export const emptyPdfBook = () => dispatch => {
    dispatch({
        type: EMPTY_PDF_BOOK,
        payload: ""
    })
}

export const emptyAllPdfBooks = () => dispatch => {
    dispatch({
        type: EMPTY_ALL_PDF_BOOKS,
        payload: ""
    })
}

export const deletePdfBook = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/PdfBook/deletePdfBook"
            const formData = new FormData();
            formData.append("ID", id);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }

            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: DELETE_PDF_BOOK,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_PDF_BOOK,
                    payload: Error.response.data.message
                })
            })
            

        })
    }
}

export const setPdfBookStatus = (id, status) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_PDF_BOOK,
            })
            const url = "/PdfBook/setPdfBookStatus"
            const formData = new FormData();
            formData.append("ID", id)
            formData.append("Status", status)

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: SETTING_PDF_BOOK_STATUS,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SETTING_PDF_BOOK_STATUS,
                    payload: Error.response.data.message// Error.message
                })
            })
        })

    }
}




export const deletePdfBookPuzzle = (id) => {

    const url = "/PdfBookPuzzle/deletePdfBookPuzzle"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("id", id);
    return axios({

        method: "delete",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}


export const savePdfBookPuzzle = (book_ID, Image_URL) => {

    const url = "/PdfBookPuzzle/addPdfBookPuzzle"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Book_ID", book_ID);
    formData.append("Image_Url", Image_URL);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}





export const deletePdfBookPaintingGame = (id) => {

    const url = "/PdfBookPaintingGame/deletePdfBookPaintingGame"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("id", id);
    return axios({

        method: "delete",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}


export const savePdfBookPaintingGame = (Book_ID, Image_URL) => {

    const url = "/PdfBookPaintingGame/addPdfBookPaintingGame"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Book_ID", Book_ID);
    formData.append("Image_Url", Image_URL);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}


/*Start of Drag Drop*/ 

export const getDragAndDropPdfBook = (DDID) => {
    const url = "/PdfBookDragDrop/getPdfBookDragAndDropData"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("PdfBookDragDrop_ID", DDID);
    return axios({
        method: "post",
        headers: {
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const savePdfBookDragAndDrop = (attributes) => {
    const url = "/PdfBookDragDrop/savePdfBookDragDrop"
    let token = firebase.auth().currentUser.h.b;
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/json',
            'X-Authorization-Firebase': token
        },
        data: {
            bookId: attributes.parentId,
            imageUrl: attributes.game_Image,
            cropedImageUrl: attributes.cropedImageUrl,
            dragDropWidth: attributes.dragDropWidth,
            dragDropHeight: attributes.dragDropHeight,
            draggableElements: attributes.listElements
        },
        url: url
    });
}

export const deletePdfBookDragAndDrop = (id) => {
    const url = "/PdfBookDragDrop/deleteDragAndDrop"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("PdfBookDragDrop_ID", id);
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const addDraggableElementPdfBook = (DDID, draggableWidth, draggableHeight, xCoordinate, yCoordinate, draggableImage) => {
    const url = "/PdfBookDragDrop/addDraggableElement"
    let token = firebase.auth().currentUser.h.b;
    return axios({
        method: "post",
        headers: {
            'X-Authorization-Firebase': token
        },
        data: {
            dragDropId: DDID,
            draggableWidth: draggableWidth,
            draggableHeight: draggableHeight,
            xCoordinate: xCoordinate,
            yCoordinate: yCoordinate,
            draggableImage: draggableImage,
        },
        url: url
    });
}

export const deleteDraggableElementPdfBook = (id) => {
    const url = "/PdfBookDragDrop/deleteDraggableElement"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("DraggableElement_ID", id);
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const updateOneDraggableElementPdfBook = (id, width, height, XCoordinate, YCoordinate, imageURL) => {
    const url = "/PdfBookDragDrop/updateOneDraggableElement";
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("DraggableId", id);
    formData.append("DraggbleWidth", width);
    formData.append("DraggbleHeight", height);
    formData.append("XCoordinate", XCoordinate);
    formData.append("YCoordinate", YCoordinate);
    formData.append("url", imageURL);

    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const updateDragAndDropPdfBook = (id, width, height, imageURL, croppedImageURL) => {
    const url = "/PdfBookDragDrop/updatePdfBookDragAndDrop";
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("DragDropWidth", width);
    formData.append("DragDropHeight", height);
    formData.append("url", imageURL);
    formData.append("Cropped_Image_Url", croppedImageURL);

    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

/*End of Drag Drop*/ 

export const emptyError = () => dispatch => {
    dispatch({
        type: EMPTY_PDF_BOOK,
        payload: ""
    })
}


export const fetchAllBookParent = () => dispatch => {

    /*dispatch({
        type: LOAD_BOOK_PARENTES,
        payload: 'loading'
    })*/
	
    axios.get('/BookParent/getAllBookParentList')
        .then((res) => {
	//console.log('pdf parent items :'+JSON.stringify(res.data,null,2));
            dispatch({
                type: GET_ALL_PARENTS,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: pdf_get_all_parent_error,
                payload: Error.message
            })
        })
}
