import {
    FETCH_STORIES, LOAD_STORIES,
    ERROR_LOADING_STORIES, ADD_STORY,
    PUBLISH_STORY,
    ERROR_ADDING_STORY, EMPTY_STORY,
    GET_STORY_DATA, ERROR_LOADING_STORY,
    LOAD_STORY,
    STORY_UPDATED, STORY_UPDATE_FAIL,
    DELETE_STORY, ERROR_DELETING_STORY,
    ERROR_SETTING_STORY_STATUS,
    SETTING_STORY_STATUS,
    EMPTY_ALL_STORIES,ADD_Story_WITH_SENTFROM,
    GET_ALL_PARENTS
} from '../Actions/Types'


const initialState = {
    items: [],
    item: {},
    loading: '',
    error_loading_stories: '',
    error_loading_story: '',
    error_adding_story: '',
    story_added_successfully: '',
    story_updated_successfully: '',
    error_editing_story: '',
    story_deleted_successfully: '',
    error_deleting_story: '',
    story_status_updated_successfully: '',
    error_updating_status: ''

	,parent_items:[],
	story_get_all_parent_error_story:'',
	story_get_all_parent_successfully:'',

}

export default function (state = initialState, action) {
    switch (action.type) {
		case ADD_Story_WITH_SENTFROM:
            return {
                ...state,
                item: action.payload
            }
        case STORY_UPDATED:
            return {
                ...state,
                item: action.payload,
                story_updated_successfully: true

            }
        case STORY_UPDATE_FAIL:
            return {
                ...state,
                story_updated_successfully: false,
                error_editing_story: action.payload
            }
        case FETCH_STORIES:
            return {
                ...state,
                items: action.payload,
                error_loading_stories: '',
                loading: false,
                item: {}
            }

        case LOAD_STORIES:
            return {
                ...state,
                loading: true
            }

        case LOAD_STORY:
            return {
                ...state,
                loading: true
            }
        case ERROR_LOADING_STORIES:
            return {
                ...state,
                error_loading_stories: action.payload,
                loading: false
            }

        case ADD_STORY:
            return {
                ...state,
                item: action.payload
            }

        case PUBLISH_STORY:
            return {
                ...state,
                item: action.payload,
                story_added_successfully: true
            }

        case ERROR_ADDING_STORY:
            return {
                ...state,
                error_adding_story: action.payload,
                story_added_successfully: false
            }
        case EMPTY_ALL_STORIES:
            return {
                ...state,
                items: [],
                item: {},
            }
        case EMPTY_STORY:
            return {
                ...state,
                error_adding_story: action.payload,
                story_added_successfully: action.payload,
                story_updated_successfully: action.payload,
                story_deleted_successfully: '',
                error_deleting_story: '',
                story_status_updated_successfully: '',
                error_updating_status: '',
                loading: false

            }

        case GET_STORY_DATA:
            return {
                ...state,
                item: action.payload,
                loading: false,
                error_loading_story: ''
            }

        case ERROR_LOADING_STORY:
            return {
                ...state,
                error_loading_story: action.payload,
                loading: false

            }

        case DELETE_STORY:
            return {
                ...state,
                story_deleted_successfully: true,
                item: action.payload
            }

        case ERROR_DELETING_STORY:
            return {
                ...state,
                story_deleted_successfully: false,
                error_deleting_story: action.payload
            }

        case SETTING_STORY_STATUS:
            return {
                ...state,
                story_status_updated_successfully: true,
                item: action.payload
            }

        case ERROR_SETTING_STORY_STATUS:
            return {
                ...state,
                story_status_updated_successfully: false,
                error_updating_status: action.payload,
                loading: false
            }

		//parent:
		case GET_ALL_PARENTS:
            return {
                ...state,
                parent_items: action.payload,
                loading: false,
                story_get_all_parent_error_story: '',
                story_get_all_parent_successfully: true,
            }
		

        default:
            return state
    }
}