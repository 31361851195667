import React, { Component } from 'react';
import { withFirebase } from '../../config';
import { Dropdown, Input, Button, Icon } from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
/*navbar*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
/*Redux */
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
// import { updateChannel, emptyChannel, getChannelData } from '../../Actions/RadioChannelAction'
import {
    updateBook, emptyBook, getBookData, fetchBooks, emptyError, updateBookPage, addBookPage,
    deleteBookPage, addBookItemWithSentFrom
} from '../../Actions/ReadWithJeelAction'
import { fetchAllSubValues } from '../../Actions/ContentSubValueAction';
// import { fetchAllRadio, getRadioData } from '../../Actions/RadioActions';
/*CSS */
import '../../css/AppCss/Episode/addEpisode.css';
import '../../css/Songs.css';
/**Other Component */
import PublishDate from '../Layout/PublishDate';
import UploadImageWithoutDropZone from '../S3Uploading/UploadImageWithoutDropZone'
import UploadAudio from '../S3Uploading/UploadAudio';
import { ALL_BOOK, BOOK_TO_EDIT, BOOK_TO_GET, EDIT_BOOK, SIGN_IN, VIEW_BOOK, VIEW_BOOK_PARENT } from '../../routes';
import UploadPageImage from '../S3Uploading/UploadPageImage';
import UploadExcelFile from '../S3Uploading/UploadExcelFile';
import { Popup } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeletePage from './DeletePage';
import CSVReader from 'react-csv-reader'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {fetchAllBookParent} from '../../Actions/BookParentAction';
import { fetchAllContentVendors } from '../../Actions/ContentVendorActions.js'
import {
    getBookParentData,
} from '../../Actions/BookParentAction';
const is_free_type = [{
    key: 0,
    text: 'paid',
    value: 'paid'
},
{
    key: 1,
    text: 'free',
    value: 'free'
}]


const INITIAL_STATE = {

    id: null,
    //selected_bookParent_id: null,
    //selected_bookParent_name: "",
    name: "",
    description: "",
    name_in_english: "",
    description_in_english: "",

    publishDate: "",
    activityId: "",

    imageUrl: "",

    titleAudioUrl: "",
    bookAudioUrl: "",
    audioMarkerUrl: "",
    showfileUploader: false,
    bookbutton: '',
    voice: '',
    subValueId: '',
    isFree: '',
    featured: false,
    pages: [],
    auth_user: {},
    contentVendorSelectedId: '',
    ageFrom:'',
    ageTo:'',

    ////////////////// validation form
    error: true,
    errorMessage: "",

    formErrors: { general: '' },
    sentFrom: "",
    item: "",

    selectedPageID: "",
    updatePageDesc: "",
    updatePageImage: "",
    pageEdit: false,

    deletePageID: "",

    newPage: false,
    newPageDesc: "",
    newPageImage: "",
    newPages: [],
    isDragable: true,
    disableUpdateStorage: true,
    pagesLoading: false

}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result && result.length && result.map((page, index) => {
        page.pageIndex = (index + 1)
    });
    let orderedResult = [...result].sort((a, b) => Number(a.pageIndex) - Number(b.pageIndex));
    return orderedResult;
};
const getItemStyle = (draggableStyle, isDragging) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: isDragging ? 'plum' : '#FFF',
    paddingBottom: "10px", marginBottom: "10px", borderBottom: "1px solid #22242626",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : '#FFF',
});

function DateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0!
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy + " " + date.getHours() + ":" + date.getMinutes();
    return date;
}

function GMTtolocalTime(GMTDate) {
    // var date = new Date(GMTDate + ' UTC')
    var date = new Date(GMTDate)
    var d = new Date(date.toString())
    var d = DateFormate(d)
    return d;
}

class EditBook extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
        this.onDragEnd = this.onDragEnd.bind(this);
        localStorage.removeItem('orderedBookPages')
    }

    saveAllPagesUpdate = async () => {
        let orderedPages = JSON.parse(localStorage.getItem('orderedBookPages')) ? JSON.parse(localStorage.getItem('orderedBookPages')) : this.state.pages
        let reOrderPages = [...orderedPages].sort((a, b) => Number(a.pageIndex) - Number(b.pageIndex));
        this.setState({
            disableUpdateStorage: true,
        })
        this.toggleDrag(true);
        this.updateData(reOrderPages)

    }
    updateData = async (orderedPages) => {
        let { readWithJeel } = this.props
        let bookitem = readWithJeel && readWithJeel.item ? readWithJeel.item : readWithJeel;
        let lastPageIndex = orderedPages[orderedPages.length - 1] ? orderedPages[orderedPages.length - 1].id : false
        orderedPages.map((page, index) => {
            this.setState({
                pagesLoading: true
            })
            this.props.updateBookPage(page.id, page.text, page.image, page.pageIndex, lastPageIndex)
        })
    }
    toggleDrag = (dragable) => {
        this.setState({
            isDragable: dragable,
            pageEdit: dragable
        })
    }
    editOrder = () => {
        let pages = this.state.pages;
        localStorage.setItem('orderedBookPages', JSON.stringify(pages));
        let allPages = JSON.parse(localStorage.getItem('orderedBookPages'))
        let orderedPages = [...allPages].sort((a, b) => Number(a.pageIndex) - Number(b.pageIndex));
        localStorage.setItem('orderedBookPages', JSON.stringify(orderedPages))
    }
    cancelOrder = async () => {

        const { readWithJeel } = this.props;
        let readWithJeelItem = readWithJeel.item ? readWithJeel.item : readWithJeel;
        let pages = readWithJeelItem.bookPages

        let orderedPages = [...pages].sort((a, b) => Number(a.pageIndex) - Number(b.pageIndex));
        localStorage.setItem('orderedBookPages', JSON.stringify(orderedPages))
        await this.setState({
            pages: orderedPages
        })
    }

    onDragEnd = async (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        let pagesState = JSON.parse(localStorage.getItem('orderedBookPages')) ? JSON.parse(localStorage.getItem('orderedBookPages')) : this.state.pages;

        const pages = reorder(
            pagesState,
            result.source.index,
            result.destination.index
        );

        localStorage.setItem('orderedBookPages', JSON.stringify(pages));
        let localPages = JSON.parse(localStorage.getItem('orderedBookPages'))
        await this.setState({
            pages: localPages
        });
    }

    componentDidMount() {
        //this.props.fetchAllBookParent();
        //console.log(' in edit Book mount : '+JSON.stringify(this.props,null,2));
        
        
        let { updatePageDesc, updatePageImage } = this.state
        

        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {
			const { readWithJeel } = this.props;
			var item = readWithJeel.item;
	
			let bookParentId =''
			let bookIdFromDetails = null
			if(this.props.location.state)
			{
			 	bookParentId = this.props.location.state.bookParentId;
			 	bookIdFromDetails=this.props.location.state.bookIdFromDetails;
			}
 		
 			console.log('loc : '+JSON.stringify(this.props.location,null,2));
 		    console.log('bookParentId :'+bookParentId);	
 		
//console.log('Checking bookIdFromDetails :'+bookIdFromDetails);
            let bookId=null ;
            if(bookIdFromDetails)
            	bookId = bookIdFromDetails;
            else            
            	bookId = item.id;
			//console.log('bookId :'+bookId); 		
 		
            //console.log(this.props.match.params);
            //const parentID = readWithJeel.item ? readWithJeel.item.parentID : readWithJeel.parentID
            const parentName = readWithJeel.item ? readWithJeel.item.parentName : readWithJeel.parentName
            //let bookParentName = parentName;
            //let bookParentId = parentID;
            //let bookId = this.props.match.params.bookId;
			console.log('Book Id is : '+bookId);	
			

            this.setState({
                id: bookId,
                selected_bookParent_id: bookParentId,
                selected_bookParent_name: parentName,
                sentFrom: readWithJeel.sentFrom ? readWithJeel.sentFrom : "ViewBook",
                item: readWithJeel.item ? readWithJeel.item : readWithJeel,
                bookParentId:bookParentId
            })
            this.props.fetchAllSubValues();
            
            this.setBookDataInState(readWithJeel)
        }
        
    }
    handleBookParentChange = (e, { value }) => {
        //console.log("selected_bookParent_id 4 = " + value[0].id);
        //console.log("selected_bookParent_name 5 = " + value[1].name);

        this.setState({ selected_bookParent_id: value[0].id })
        this.setState({ selected_bookParent_name: value[1].name })
    }

    setBookDataInState(data) {
        let readWithJeel = data.item ? data.item : data;

        let paymentAvailability = ''
        if (readWithJeel.isFree == 0) {
            paymentAvailability = 'paid'
        }
        else if (readWithJeel.isFree == 1) {
            paymentAvailability = 'free'
        }

        // let audio = readWithJeel.voice;
        // if (readWithJeel.voice == undefined &&
        //     readWithJeel.voice == null &&
        //     readWithJeel.voice == "undefined"
        //     && readWithJeel.voice == "null"
        //     && readWithJeel.voice == 'null') {
        //     audio = null
        // }

        let titleAudio = readWithJeel.voice;
        if (readWithJeel.voice == undefined &&
            readWithJeel.voice == null &&
            readWithJeel.voice == "undefined"
            && readWithJeel.voice == "null"
            && readWithJeel.voice == 'null') {
            titleAudio = null
        }


        let bookAudio = readWithJeel.bookVoice;
        if (readWithJeel.bookVoice == undefined &&
            readWithJeel.bookVoice == null &&
            readWithJeel.bookVoice == "undefined"
            && readWithJeel.bookVoice == "null"
            && readWithJeel.bookVoice == 'null') {
            bookAudio = null
        }

        let audioMarker = readWithJeel.bookMarker;
        if (readWithJeel.bookMarker == undefined &&
            readWithJeel.bookMarker == null &&
            readWithJeel.bookMarker == "undefined"
            && readWithJeel.bookMarker == "null"
            && readWithJeel.bookMarker == 'null') {
            audioMarker = null
        }

        /////////// Uncomment this line /////////////
        // let subvalue = ''
        // if (readWithJeel.contentSubValue === undefined ||
        //     readWithJeel.contentSubValue === null) {
        //     subvalue = ''
        // } else {
        //     subvalue = readWithJeel.contentSubValue.id
        // }

        let subvalue = ''
        if (readWithJeel.subValueId === undefined ||
            readWithJeel.subValueId === null) {
            subvalue = ''
        } else {
            subvalue = readWithJeel.subValueId
        }
        if (!localStorage.getItem('orderedBookPages')) {
            localStorage.setItem('orderedBookPages', JSON.stringify(readWithJeel.bookPages ? readWithJeel.bookPages : []));
        }
        
        let bookParentId =''
		if(this.props.location.state)
 			bookParentId = this.props.location.state.bookParentId;
        
        this.setState(
            {
                id: readWithJeel.id,
                name: readWithJeel.name,
                description: readWithJeel.description,
                imageUrl: readWithJeel.image,
                status: readWithJeel.status,
                titleAudioUrl: titleAudio,
                bookAudioUrl: bookAudio,
                publishDate: readWithJeel.publishDate,
                // Uncomment this line ///////////////////////
                // activityId: readWithJeel.activityId.id,
                activityId: readWithJeel.activityId,
                isFree: paymentAvailability,
                subValueId: subvalue,
                pages: readWithJeel.bookPages ? readWithJeel.bookPages : [],
                audioMarkerUrl: audioMarker,
                name_in_english: readWithJeel.nameInEnglish,
                description_in_english: readWithJeel.descriptionInEnglish,
                featured: readWithJeel.featured,
                contentVendorSelectedId: readWithJeel.contentVendor?readWithJeel.contentVendor.id:'',
                bookParentId:bookParentId,
                ageFrom:readWithJeel.ageFrom,
    			ageTo:readWithJeel.ageTo,
            })

            // let generalValidation = this.state.formErrors
            // if (readWithJeel.nameInEnglish !== null && readWithJeel.nameInEnglish !== undefined ) {
            //     generalValidation.general = '';
            //     this.setState({ formErrors: generalValidation });
            // }else {
            //     generalValidation.general = "Empty Data is not accepted";
            //     this.setState({ formErrors: generalValidation });
            // }

    }

handleAgeFromSelection = (e, { value }) => {
       this.setState({ ageFrom: value })
    }
handleAgeToSelection = (e, { value }) => {
       this.setState({ ageTo: value })
    }
    
    getImageUrl(imageUrl) {
        if (imageUrl) {
            this.setState({ imageUrl: imageUrl })
        }
    }

    getPublishDate(publishDate) {
        if (publishDate) {
            this.setState({ publishDate: publishDate });
        }
    }

    getAudio(audioUrl) {
        if (audioUrl) {

            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {
                this.setState({ voice: '' });
            }
            // else send the url to DB
            else {
                this.setState({ voice: audioUrl });
            }

        }
    }


    getTitleAudio(audioUrl) {
        if (audioUrl) {

            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {
                this.setState({ titleAudioUrl: '' });
            }
            // else send the url to DB
            else {
                this.setState({ titleAudioUrl: audioUrl });
            }

        }
    }


    getBookAudio(audioUrl) {
        if (audioUrl) {

            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {
                this.setState({ bookAudioUrl: '' });
            }
            // else send the url to DB
            else {
                this.setState({ bookAudioUrl: audioUrl });
            }

        }
    }

    updateBook(id, name, description, Image,
        PublishDate, titleAudio, bookAudio, audioMarker,
        activityId,
        pages,
        isFree,
        subValueId,name_in_english, description_in_english, featured,contentVendorSelectedId,bookParentId,ageFrom,ageTo) {

        this.props.updateBook(id, name, description, Image,
            PublishDate, titleAudio, bookAudio, audioMarker,
            activityId,
            pages,
            isFree,
            subValueId, name_in_english, description_in_english, featured,contentVendorSelectedId,bookParentId,ageFrom,ageTo);
    }

    handleChange = (e) => {
        let generalValidation = this.state.formErrors;

        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        let nameOfState = e.target.name;

        switch (e.target.name) {
            case 'description':
                this.setState({ [e.target.name]: checkSpaces });
                break;
            case 'name_in_english':
                this.setState({ [e.target.name]: checkSpaces });
                break;
            default:
                setTimeout(() => {
                    if (checkSpaces === '' || checkSpaces === null || checkSpaces === ' ') {
                        generalValidation.general = "Empty Data is not accepted";
                        this.setState({ formErrors: generalValidation });
                    } else {
                        generalValidation.general = '';
                        this.setState({ formErrors: generalValidation });
                    }

                    if ((this.state.imageUrl === '' ||
                        this.state.imageUrl === null ||
                        this.state.imageUrl === undefined)
                        || (this.state.name === '' ||
                            this.state.name === null ||
                            this.state.name === ' ')
                        ||  this.state.description_in_english.length > 255    
                        // || (this.state.name_in_english === '' ||
                        //     this.state.name_in_english === null ||
                        //     this.state.name_in_english === ' ')
                    ) {
                        {
                            generalValidation.general = nameOfState === "description_in_english" ? 
                            "Description in English is too long (greater than 255 characters)." : 
                            "Empty Data is not accepted";
                            this.setState({ formErrors: generalValidation });
                        }
                    }

                }, 0);
        }
    }

    handlePaymentAva = (e, { value }) => {

        console.log("value in handle change = " + value)

        this.setState({
            isFree: value
        })

    }

    handleSubValueChange = (e, { value }) => {
        this.setState({ subValueId: value });
    };

    handleSubmit = (e) => {

        if (e.nativeEvent.submitter.id === "nextButton") {
            const { id, name, description, imageUrl,
                publishDate, titleAudioUrl, bookAudioUrl,
                activityId,
                isFree,
                subValueId,
                pages, audioMarkerUrl, name_in_english, description_in_english, featured,contentVendorSelectedId,bookParentId,ageFrom,ageTo } = this.state;

	if(ageFrom){
		if(ageTo && ageTo< ageFrom){
			alert('Please Enter AgeTo greater than ageFrom')
			return false;	
		}
	}

            this.updateBook(id, name, description, imageUrl,
                publishDate, titleAudioUrl, bookAudioUrl, audioMarkerUrl,
                activityId,
                pages,
                isFree,
                subValueId, name_in_english, description_in_english, featured,contentVendorSelectedId,bookParentId,ageFrom,ageTo);
        }

        /// calling save
        e.preventDefault();

    }
    
    
    handleContentVendorSelection = (e, { value }) => {
        //console.log("contentVendor selected: " + value);
        this.setState({ contentVendorSelectedId: value })
    }
    

    getFileUrl(fileURL) {
        if (fileURL) {
            this.setState({
                audioMarkerUrl: fileURL,
                showfileUploader: false
            });

        }
    }

    onCSVFileLoaded = (data, fileInfo, originalFile) => {
        // console.log(JSON.stringify(data))
        if (data) {
            this.setState({
                audioMarkerUrl: JSON.stringify(data),
                showfileUploader: false
            });
            // alert("The file has been uploaded successfully")
        }
    }
    onCSVFileError = () => {
        alert("File Upload Failed")
    }

    handleFileUploaderPreview = (e) => {
        const { showfileUploader } = this.state;

        if (showfileUploader === true) {
            this.setState({
                showfileUploader: false
            })
        }
        else {
            this.setState({
                showfileUploader: true
            })
        }
    }

    getPageImageUrl(imageUrl, index) {
        let { pages } = this.state;
        if (imageUrl === 'delete') {
            pages[index].imgUrl = ''
            this.setState({
                pages,
                error: true,
                errorMessage: "Empty Data is not accepted"
            });
        }
        else if (imageUrl !== undefined && imageUrl !== '') {
            pages[index].imgUrl = imageUrl
            this.setState({ pages });
            if (this.state.name !== ''
                && this.state.name !== ' '
                && this.state.name !== null
                // && this.state.name_in_english !== ''
                // && this.state.name_in_english !== ' '
                // && this.state.name_in_english !== null
            ) {
                this.setState({
                    error: false,
                    errorMessage: ""
                });
            }
        }
    }

    handleCheckboxChange = () => {
        this.setState({ featured: !this.state.featured })
      };

    handlePageDescChange = (e, index) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        let { pages } = this.state;
        if (
            (checkSpaces === ''
                || checkSpaces === null
                || checkSpaces === ' ')
        ) {
            this.setState({
                error: true,
                errorMessage: "Empty Data is not accepted"
            });
        }
        else if (
            (checkSpaces !== ''
                && checkSpaces !== null
                && checkSpaces !== ' ')
        ) {
            this.setState({ error: false, errorMessage: "" });
        }
        setTimeout(() => {
            pages[index].description = checkSpaces.trim()
            this.setState({
                pages
            })
        }, 0);
    }

    enableEdit = async (e, pageID) => {
        document.getElementById("pageDescription_" + pageID).disabled = false
        document.getElementById("pageViewImage_" + pageID).classList.add('d-none')
        document.getElementById("pageViewImage_" + pageID).classList.remove('d-flex')
        document.getElementById("editPageBtn_" + pageID).classList.add('d-none')

        document.getElementById("pageEditImage_" + pageID).classList.remove('d-none')
        document.getElementById("savePageBtn_" + pageID).classList.remove('d-none')
        document.getElementById("cancelPageBtn_" + pageID).classList.remove('d-none')

        // this.props.getBookData(pageID)
        let { pages, updatePageDesc, updatePageImage } = this.state
        let { readWithJeel } = this.props
        pages.filter((item) => {
            if (item && item.id && item.id == pageID) {
                // item.text = updatePageDesc;
                // item.image = updatePageImage

                this.setState({
                    updatePageDesc: item.text ? item.text : "",
                    updatePageImage: item.image ? item.image : "",
                })
            }
            // return item
        })
        await this.setState({
            selectedPageID: pageID,
            pageEdit: true
        })
    }

    disableEdit = (pageID) => {
        document.getElementById("pageDescription_" + pageID).disabled = true
        document.getElementById("pageViewImage_" + pageID).classList.remove('d-none')
        document.getElementById("pageViewImage_" + pageID).classList.add('d-flex')

        document.getElementById("editPageBtn_" + pageID).classList.remove('d-none')

        document.getElementById("pageEditImage_" + pageID).classList.add('d-none')
        document.getElementById("savePageBtn_" + pageID).classList.add('d-none')
        document.getElementById("cancelPageBtn_" + pageID).classList.add('d-none')
    }

    updatePageDescContent = async (e, id) => {
        let { updatePageDesc } = this.state
        let pages = JSON.parse(localStorage.getItem('orderedBookPages'))
        let pageID = id;
        // updatePageDesc = e.target.value
        pages.find(page => page.id == pageID).text = e.target.value
        localStorage.setItem('orderedBookPages', JSON.stringify(pages))

        await this.setState({
            // updatePageDesc,
            pages
        })
    }

    updatePageImageContent = async (imageUrl, id) => {
        let pages = JSON.parse(localStorage.getItem('orderedBookPages'))
        let pageID = id;
        pages.find(page => page.id == pageID).image = imageUrl

        let { updatePageImage } = this.state
        // updatePageImage = imageUrl
        localStorage.setItem('orderedBookPages', JSON.stringify(pages))

        await this.setState({
            // updatePageImage,
            pages
        })
        // 

        // updatePageDesc = e.target.value


        // await this.setState({
        //     updatePageDesc,
        //     pages
        // })
        // localStorage.setItem('orderedBookPages', JSON.stringify(pages))
    }
    resetSelectedPage = async () => {
        await this.setState({
            selectedPageID: "",
            updatePageImage: "",
            updatePageDesc: "",
            pageEdit: false
        })
    }
    savePageUpdate = async (pageID) => {
        let { updatePageDesc, updatePageImage } = this.state
        let bookID = this.props.readWithJeel.item && this.props.readWithJeel.item.id ? this.props.readWithJeel.item.id : this.props.readWithJeel.id

        if (pageID) {
            await this.props.updateBookPage(pageID, updatePageDesc, updatePageImage)
            let { readWithJeel } = this.props
            let bookitem = readWithJeel && readWithJeel.item ? readWithJeel.item : readWithJeel;
            this.props.addBookItemWithSentFrom(bookitem, 'ViewBook');
            this.disableEdit(pageID)
        }

        // this.resetSelectedPage()
        return (<Redirect to={BOOK_TO_EDIT}></Redirect>)
    }

    deletePage = (pageID) => {
        this.props.deleteBookPage(pageID)
    }

    cancelEditPage = (e, index) => {
        this.resetSelectedPage()
    }


    bookPagesList = (e) => {
        let { pages, selectedPageID, updatePageDesc, updatePageImage } = this.state
        let allPages = JSON.parse(localStorage.getItem('orderedBookPages')) ? JSON.parse(localStorage.getItem('orderedBookPages')) : pages
        let orderedPages = [...allPages].sort((a, b) => Number(a.pageIndex) - Number(b.pageIndex));
        localStorage.setItem('orderedBookPages', JSON.stringify(orderedPages))
        let pagesList = orderedPages && orderedPages.length ? orderedPages.map((page, index) => {
            return (
                <Draggable
                    key={page.id} // => page.id
                    draggableId={page.id ? page.id.toString() : Math.random().toString()} // => page.id
                    index={index}
                    isDragDisabled={this.state.isDragable}
                >
                    {(provided, snapshot) => (
                        <div>
                            <div ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                style={getItemStyle(
                                    provided.draggableProps.style,
                                    snapshot.isDragging
                                )} key={index} className="page-item">
                                <div className="page-inputs">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div style={{ backgroundColor: "#e0e1e2", fontWeight: "bold", padding: "10px", marginBottom: "5px" }}>
                                                {page.pageIndex ? "Page No. " + page.pageIndex : "Page Without Number"}
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-4'>
                                            <div className={`page-image ${!this.state.pageEdit && !this.state.isDragable ? '' : 'd-none'}`}
                                                id={`pageEditImage_${page.id}`}>
                                                {
                                                    !this.state.pageEdit && !this.state.isDragable ?
                                                        <UploadImageWithoutDropZone
                                                            getImageURLCallBack={this.updatePageImageContent.bind(this)}
                                                            imageUrl={page.image}
                                                            imagepreview={page.image}
                                                            bookPageID={page.id}
                                                            s3DirectryrName='readWithJeel/bookPages' />

                                                        :
                                                        selectedPageID && selectedPageID == page.id ?
                                                            // <UploadPageImage
                                                            //     getImageURLCallBack={this.getPageImageUrl.bind(this)}
                                                            //     imageUrl={updatePageImage}
                                                            //     itemIndex={index}
                                                            //     imagepreview={updatePageImage}
                                                            //     s3DirectryrName='readWithJeel/bookPages'
                                                            // />
                                                            <UploadImageWithoutDropZone
                                                                getImageURLCallBack={this.updatePageImageContent.bind(this)}
                                                                imageUrl={updatePageImage ? updatePageImage : page.image}
                                                                imagepreview={updatePageImage ? updatePageImage : page.image}
                                                                s3DirectryrName='readWithJeel/bookPages' />

                                                            :

                                                            // 
                                                            <UploadImageWithoutDropZone
                                                                getImageURLCallBack={this.updatePageImageContent.bind(this)}
                                                                imageUrl={page.image}
                                                                imagepreview={page.image}
                                                                s3DirectryrName='readWithJeel/bookPages' />

                                                    // 
                                                    // <UploadPageImage
                                                    //     getImageURLCallBack={this.getPageImageUrl.bind(this)}
                                                    //     imageUrl={page.image}
                                                    //     itemIndex={index}
                                                    //     imagepreview={page.image}
                                                    //     s3DirectryrName='readWithJeel/bookPages'
                                                    // />
                                                }

                                            </div>

                                            <div className={`page-image  align-items-center justify-content-center ${!this.state.pageEdit && !this.state.isDragable ? 'd-none' : 'd-flex'}`}
                                                id={`pageViewImage_${page.id}`}>
                                                <img style={{ maxWidth: "100%" }} src={page.image} alt="Page Image" />
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-8'>
                                            <div className="page-description h-100">
                                                {
                                                    !this.state.pageEdit && !this.state.isDragable ?
                                                        <textarea dir="auto"
                                                            id={`pageDescription_${page.id}`}
                                                            className="form-control"
                                                            style={{ height: "100%" }}
                                                            // id="exampleFormControlTextarea1"
                                                            rows="6"
                                                            name="pageDescription"
                                                            onChange={(e) => this.updatePageDescContent(e, page.id)}
                                                            defaultValue={page.text}
                                                        /> :
                                                        selectedPageID && selectedPageID == page.id ?
                                                            <textarea dir="auto"
                                                                disabled
                                                                id={`pageDescription_${page.id}`}
                                                                className="form-control"
                                                                style={{ height: "100%" }}
                                                                // id="exampleFormControlTextarea1"
                                                                rows="6"
                                                                name="pageDescription"
                                                                onChange={(e) => this.updatePageDescContent(e, index)}
                                                                defaultValue={updatePageDesc}
                                                            />
                                                            :
                                                            <textarea dir="auto"
                                                                disabled
                                                                id={`pageDescription_${page.id}`}
                                                                className="form-control"
                                                                style={{ height: "100%" }}
                                                                // id="exampleFormControlTextarea1"
                                                                rows="6"
                                                                name="pageDescription"
                                                                onChange={(e) => this.handlePageDescChange(e, page.id)}
                                                                value={page.text}
                                                            />
                                                }

                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="page-action" style={{ display: 'flex', alignItems: "center", textAlign: "end", marginTop: "10px" }}>
                                    {/* <DeletePage id={index} /> */}

                                    {/* <button className="ui button primary  editPage"
                                        type="button"
                                        id={`editPageBtn_${page.id}`}
                                        disabled={this.state.pageEdit}
                                        onClick={(e) => this.enableEdit(e, page.id)}>
                                        Edit
                                    </button>

                                    <button className="ui button editPage positive d-none"
                                        type="button"
                                        id={`savePageBtn_${page.id}`}
                                        onClick={(e) => { this.savePageUpdate(page.id) }}>
                                        Save
                                    </button>

                                    <button className="ui button editPage d-none"
                                        type="button"
                                        id={`cancelPageBtn_${page.id}`}
                                        onClick={(e) => { this.cancelEditPage(e, index); this.disableEdit(page.id) }}>
                                        Cancel
                                    </button> */}
                                    {/* <button style={{ marginInlineStart: "auto" }} type="button" className="ui button" onClick={(e) => this.deletePage(page.id)}>
                            <FontAwesomeIcon icon="trash-alt" />
                        </button> */}
                                    {
                                        !this.state.pageEdit && !this.state.isDragable || this.state.pagesLoading ? "" :
                                            <DeletePage id={page.id}
                                                getDataCallBack={this.getData}
                                                setPageIDToDelete={this.setPageIDToDelete} />
                                    }

                                    {
                                        !this.state.pageEdit && !this.state.isDragable ?
                                            <div className='dnd_bars_icon' style={{ marginInlineStart: 'auto' }}>
                                                <div className='dnd_bar' style={{ marginBottom: '5px', height: '2px', width: '20px', backgroundColor: "purple" }}></div>
                                                <div className='dnd_bar' style={{ marginBottom: '5px', height: '2px', width: '20px', backgroundColor: "purple" }}></div>
                                                <div className='dnd_bar' style={{ marginBottom: '5px', height: '2px', width: '20px', backgroundColor: "purple" }}></div>
                                            </div>
                                            :
                                            ''
                                    }


                                </div>
                            </div>
                            {provided.placeholder}
                        </div>
                    )}
                </Draggable>

            )
        }) : <div className="text-center">No Pages</div>
        // return pagesList
        let dragList = ''
        dragList = pages && pages.length ? (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                        >
                            {
                                pagesList
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        ) : dragList = <div className="text-center">No Pages</div>
        return dragList
    }

    addBookPage = (e) => {
        let page = { image: "", text: "", pageIndex: (this.state.pages.length + 1) }
        let { newPages, newPage } = this.state
        if (!newPage) {
            newPages.push(page)
            this.setState({
                newPages,
                newPage: true
            })
        }
    }
    hanldeNewPage = () => {
        this.setState({
            newPage: true,
            newPageDesc: "",
            newPageImage: "",
        })
    }

    handleNewPageDesc = async (e) => {
        let { newPageDesc } = this.state
        newPageDesc = e.target.value
        await this.setState({
            newPageDesc
        })
    }

    handleNewPageImage = async (imageUrl, index) => {
        let { newPageImage } = this.state
        newPageImage = imageUrl
        await this.setState({
            newPageImage
        })
    }
    saveNewPage = async () => {
        // Book ID / Text / Image
        let bookID = this.props.readWithJeel.item && this.props.readWithJeel.item.id ? this.props.readWithJeel.item.id : this.props.readWithJeel.id

        let { pages, newPageDesc, newPageImage, newPages } = this.state;
        if (newPageDesc == '' || newPageDesc == null) {
            alert('Please Add Text')
            this.props.getBookData(bookID);
            return (<Redirect to={BOOK_TO_EDIT}></Redirect>)
        }
        await this.props.addBookPage(bookID, newPageDesc, newPageImage, newPages[0].pageIndex)
        let bookPagesListLocalItems = JSON.parse(localStorage.getItem('orderedBookPages'))
        newPages.text = newPageDesc
        newPages.image = newPageImage
        let allPages = bookPagesListLocalItems.concat(newPages)
        localStorage.setItem('orderedBookPages', JSON.stringify(allPages))
        await this.setState({
            newPageDesc: "",
            newPageImage: "",
            newPages: [],
            newPage: false,
            disableUpdateStorage: false
        })
        this.props.getBookData(bookID);
        return (<Redirect to={BOOK_TO_EDIT}></Redirect>)
    }

    static getDerivedStateFromProps(nextprops, state) {

        if (nextprops.readWithJeel && nextprops.readWithJeel.bookPages && nextprops.readWithJeel.bookPages != state.pages) {
            if (!localStorage.getItem('orderedBookPages')) {
                localStorage.setItem('orderedBookPages', JSON.stringify(nextprops.readWithJeel.bookPages));
            }

            if (!state.newPage && state.isDragable && !state.disableUpdateStorage) {
                localStorage.setItem('orderedBookPages', JSON.stringify(nextprops.readWithJeel.bookPages));
            }
            let pagesUpdateLoading = false;
            if (nextprops.readWithJeel && nextprops.readWithJeel.lastPageLoading && nextprops.readWithJeel.lastPageLoading == false) {
                pagesUpdateLoading = false
                localStorage.setItem('orderedBookPages', JSON.stringify(nextprops.readWithJeel.bookPages));
            } else if (nextprops.readWithJeel && nextprops.readWithJeel.lastPageLoading && nextprops.readWithJeel.lastPageLoading == true) {
                pagesUpdateLoading = true
            }
            // console.log('pagesUpdateLoading', pagesUpdateLoading)
            return {
                pages: nextprops.readWithJeel.bookPages,
                pagesLoading: pagesUpdateLoading
            }
        }

        if (nextprops.readWithJeel && nextprops.readWithJeel.item && nextprops.readWithJeel.item.bookPages && nextprops.readWithJeel.item.bookPages != state.pages) {
            return {
                pages: nextprops.readWithJeel.item.bookPages
            }
        }
        if (nextprops.readWithJeel && nextprops.readWithJeel.disableUpdateStorage != state.disableUpdateStorage) {
            return {
                disableUpdateStorage: nextprops.readWithJeel.disableUpdateStorage
            }
        }

        // if (nextprops.readWithJeel && nextprops.readWithJeel.loadingPages != state.pagesLoading) {
        //     return {
        //         pagesLoading: nextprops.readWithJeel.loadingPages
        //     }
        // }

        return state;
    }

    cancelNewPage = async () => {
        await this.setState({
            newPageDesc: "",
            newPageImage: "",
            newPages: [],
            newPage: false
        })
    }
    newPagesList = () => {
        let { newPages, selectedPageID, newPageImage,
            newPageDesc } = this.state
        let pagesList = newPages && newPages.length ? newPages.map((page, index) => {
            return (
                <div key={"newPage" + index} className="page-item" style={{ paddingBottom: "10px", marginBottom: "10px", borderBottom: "1px solid #22242626" }}>
                    <div className="page-inputs">
                        <div className='row'>
                            <div className='col-12'>
                                <div style={{ backgroundColor: "#e0e1e2", fontWeight: "bold", padding: "10px", marginBottom: "5px" }}>
                                    {page.pageIndex ? "Page No. " + page.pageIndex : "Page Without Number"}
                                </div>
                            </div>
                            <div className='col-12 col-sm-4'>
                                <div className="page-image"
                                    id={`pageNewImage_${index}`}>
                                    <UploadImageWithoutDropZone
                                        getImageURLCallBack={this.handleNewPageImage.bind(this)}
                                        imageUrl={newPageImage ? newPageImage : ''}
                                        imagepreview={newPageImage ? newPageImage : ''}
                                        s3DirectryrName='readWithJeel/bookPages' />

                                </div>
                            </div>
                            <div className='col-12 col-sm-8'>
                                <div className="page-description h-100">
                                    <textarea dir="auto"
                                        id={`newPageDescription_${index}`}
                                        className="form-control"
                                        style={{ height: "100%" }}
                                        // id="exampleFormControlTextarea1"
                                        rows="6"
                                        name="pageDescription"
                                        onChange={(e) => this.handleNewPageDesc(e)}
                                        defaultValue={newPageDesc}
                                    />
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="page-action" style={{ textAlign: "end", marginTop: "10px" }}>
                        <button className="ui button positive editPage"
                            type="button"
                            id={`saveNewPageBtn_${index}`}
                            onClick={(e) => { this.saveNewPage() }}>
                            Save
                        </button>

                        <button className="ui button editPage"
                            type="button"
                            id={`cancelNewPageBtn_${index}`}
                            onClick={(e) => { this.cancelNewPage() }}>
                            Cancel
                        </button>
                    </div>
                </div>
            )
        }) : ""
        return pagesList
    }
    setPageIDToDelete = async (id) => {
        await this.setState({
            deletePageID: id
        })
    }

    getData = async () => {

    }
    render() {
        const papaparseOptions = {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
        };
        const { sentFrom, item } = this.state;
        const { error_update, success_update } = this.props;
        const { allSubValues } = this.props;
        const subValuesList = [];
        for (let i = 0; i < allSubValues.length; i++) {
            subValuesList.push({
                key: allSubValues[i].id,
                text: allSubValues[i].contentValue,
                value: allSubValues[i].id
            });
        }


// --------------------------------
    const { ContentVendorsList } = this.props;
    const ContentVendorsArr = [];
    
    //console.log('ContentVendorsList : '+JSON.stringify(ContentVendorsList,null,2));
    
    for (let i = 0; i < ContentVendorsList.length; i++) 
    {
      ContentVendorsArr.push({
        key: ContentVendorsList[i].id,
        text: ContentVendorsList[i].user.name,
        value: ContentVendorsList[i].id,
      });
    }
// --------------------------------
const AgeFromArr = [];

for (let i = 1; i <= 10; i++) 
    {
      AgeFromArr.push({
        key: i,
        text: i,
        value: i,
      });
    }
// --------------------------------


        const { success,
            error, loading } = this.props
        // successPageUpdate errorPageUpdate
        const { successPageUpdate,
            errorPageUpdate, loadingPages, successDeletePage, errorDeletePage, addNewPageSuccess,
            addNewPageError } = this.props
        let { updatePageDesc, updatePageImage, selectedPageID } = this.state;

        // ADD
        if (addNewPageSuccess === true) {
            // alert("error missing data or network " + error)
            alert("Page added successfully")
            this.props.getBookData(item.id);
            this.props.emptyError();

            this.resetSelectedPage()
            return (<Redirect to={BOOK_TO_EDIT}></Redirect>)
        }

        if (addNewPageSuccess === false) {
            alert("error missing data or network " + addNewPageError)
            this.props.emptyError();
        }
        // UPDATE
        if (successPageUpdate === true) {
            // alert("error missing data or network " + error)
            // alert("The page has been successfully updated")
            this.props.getBookData(item.id);
            this.props.emptyError();

            this.resetSelectedPage()
            return (<Redirect to={BOOK_TO_EDIT}></Redirect>)
        }

        if (successPageUpdate === false) {
            // alert("error missing data or network " + errorPageUpdate)
            this.props.emptyError();
        }

        // DELETE

        if (successDeletePage === true) {
            let { pages, deletePageID } = this.state
            // alert("error missing data or network " + error)
            alert("The page has been deleted")
            this.props.getBookData(item.id);
            this.props.emptyError();

            this.resetSelectedPage()
            return (<Redirect to={BOOK_TO_EDIT}></Redirect>)
        }

        if (successDeletePage === false) {
            alert("error missing data or network " + errorDeletePage)
            this.props.emptyError();
        }

        // ////////
        // loading button
        let $buttonSubmitLoader = (
            <Button disabled={this.state.formErrors.general}
                type="submit" id="nextButton" >
                Save
            </Button>);

        if (loading === true) {
            $buttonSubmitLoader = (
                <button id="nextButton"
                    class="ui loading button">Loading</button>);
        }

        if (success === false) {
            alert("error missing data or network " + error)
            this.props.emptyError();
        }
        else if (success === true
            || this.state.cancel === true) {
            if (success === true) {
                alert("Book updated Successfully")
                this.props.emptyError();
            }
            // if the requesst come from All Book then
            //  fetch all Book to update the item data
			//console.log('sentFrom :'+sentFrom);
            if (sentFrom === "GetAllBook") {
                this.props.fetchBooks();
                // return (<Redirect to={ALL_BOOK}></Redirect>)
                this.props.getBookParentData(this.state.bookParentId);
                //return (<Redirect to={VIEW_BOOK_PARENT}></Redirect>)
				return (<Redirect to={{pathname:VIEW_BOOK_PARENT,state: {toTab:'BOOK'}}}></Redirect>)
            }

            // if the requesst come from ViewRadio then
            //  get Book data to update the item data
            if (sentFrom === "ViewBook") {
                this.props.getBookData(item.id);
                // return (<Redirect to={BOOK_TO_GET}></Redirect>)
                this.props.getBookParentData(this.state.bookParentId);
                //return (<Redirect to={VIEW_BOOK_PARENT}></Redirect>)
                return (<Redirect to={{pathname:VIEW_BOOK_PARENT,state: {toTab:'BOOK'}}}></Redirect>)

            }

            if (!sentFrom) {
                this.props.getBookData(item.id);
                // return (<Redirect to={BOOK_TO_GET}></Redirect>)
                this.props.getBookParentData(this.state.bookParentId);
                //return (<Redirect to={VIEW_BOOK_PARENT}></Redirect>)
                return (<Redirect to={{pathname:VIEW_BOOK_PARENT,state: {toTab:'BOOK'}}}></Redirect>)
            }

        }

        const { name, imageUrl,
            publishDate,
            isFree, audioMarkerUrl, showfileUploader } = this.state;

        const isInvalid =
            name === '' || name == undefined ||
            imageUrl === '' || imageUrl == undefined ||
            // publishDate === '' || publishDate == undefined ||
            isFree === '' || isFree == undefined;

        // 


        let fileLink;
        if (audioMarkerUrl !== undefined &&
            audioMarkerUrl !== null &&
            audioMarkerUrl !== '') {
            fileLink = (
                <div style={{ maxWidth: '100%' }}>
                    <span style={{ wordBreak: "break-all" }}>
                        Audio Marker : {" "}
                        <button type="button" className="ui blue button" data-toggle="modal" data-target="#merkerDataModal">
                            View Marker Data
                        </button>
                        <div className="modal fade bd-example-modal-lg" id="merkerDataModal" tabindex="-1" role="dialog" aria-labelledby="merkerDataModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="merkerDataModalLabel">Marker Data</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {/* ...Table... */}
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th style={{ borderTop: "none" }} scope="col">Name</th>
                                                    <th style={{ borderTop: "none" }} scope="col">Description</th>
                                                    <th style={{ borderTop: "none" }} scope="col">Start</th>
                                                    <th style={{ borderTop: "none" }} scope="col">Duration</th>
                                                    <th style={{ borderTop: "none" }} scope="col">Type</th>
                                                    <th style={{ borderTop: "none" }} scope="col">Time Format</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    JSON.parse(audioMarkerUrl).length ? JSON.parse(audioMarkerUrl).map((markerItem, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{markerItem.name ? markerItem.name : ""}</td>
                                                                <td>{markerItem.description ? markerItem.description : ""}</td>
                                                                <td>{markerItem.start ? markerItem.start : ""}</td>
                                                                <td>{markerItem.duration ? markerItem.duration : ""}</td>
                                                                <td>{markerItem.type ? markerItem.type : ""}</td>
                                                                <td>{markerItem.time_format ? markerItem.time_format : ""}</td>
                                                            </tr>
                                                        )
                                                    }) : ''
                                                }
                                            </tbody>
                                        </table>
                                        {/* END */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                    <div className="float-right">
                        <Popup trigger=
                            {<FontAwesomeIcon
                                icon='edit'
                                className="far fa-edit" onClick={this.handleFileUploaderPreview}>
                            </FontAwesomeIcon>
                            }
                            content="Upload File"
                            position="top center" />
                    </div>
                </div>
            );
        }

        let uploadFile = (<CSVReader
            onFileLoaded={this.onCSVFileLoaded}
            onError={this.onCSVFileError}
            parserOptions={papaparseOptions}
        />
        )

        if (showfileUploader == false) {
            uploadFile = (
                <div style={{ maxWidth: '100%' }}>
                    <div className="d-none">
                        <CSVReader
                            onFileLoaded={this.onCSVFileLoaded}
                            onError={this.onCSVFileError}
                            parserOptions={papaparseOptions}
                        />
                    </div>
                    <br />
                    <br />
                </div>
            )
        }

        if (audioMarkerUrl == undefined ||
            audioMarkerUrl == null ||
            audioMarkerUrl == '') {
            uploadFile = (<div style={{ maxWidth: '100%' }}>
                <span style={{ wordBreak: "break-all" }}> Audio Marker Link : </span>
                <div className="">
                    <CSVReader
                        onFileLoaded={this.onCSVFileLoaded}
                        onError={this.onCSVFileError}
                        parserOptions={papaparseOptions}
                    />
                </div>
                <br />
                <br />
            </div>)

        }

        const{bookParent} = this.props;
        const bookParentList = [];
        for (let i = 0; i < bookParent.length; i++) {
            bookParentList.push({
                key: bookParent[i].id,
                text: bookParent[i].name,
                value: [{ id: bookParent[i].id }, { name: bookParent[i].name }]
            })
        }
        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <div className="container">

                                                    <form onSubmit={this.handleSubmit}>
                                                        <div className='d-flex justify-content-between align-items-center mb-3'>
                                                            <h2 className="label mb-0">Edit Book</h2>
                                                            {$buttonSubmitLoader}

                                                        </div>
                                                        <br />


                                                        <p style={{ color: "red" }}>
                                                            {this.state.formErrors.general}
                                                        </p>


                                                        <label htmlFor="Image" className="label">
                                                            Book Image
                                                        </label>
                                                        <br />

                                                        <div className="row">

                                                            <div className=" col-4">
                                                                <UploadImageWithoutDropZone
                                                                    getImageURLCallBack={this.getImageUrl.bind(this)}
                                                                    imageUrl={this.state.imageUrl}
                                                                    imagepreview={this.state.imageUrl}
                                                                    s3DirectryrName='readWithJeel' />

                                                            </div>
                                                        </div>

                                                        <br />

                                                        <div className="input-field">
                                                            <label htmlFor="name"
                                                                className="label">
                                                                    <span className="required_input">*</span>
                                                                    Book Name</label><br />
                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                type="text" name="name"
                                                                fluid id="name"
                                                                onChange={this.handleChange}
                                                                value={this.state.name}
                                                                required />
                                                        </div>
                                                        <br />

                                                        
                                                        <div className="input-field">
                                                            <label htmlFor="name_in_english"
                                                                className="label">Book Name In English</label><br />
                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                type="text" name="name_in_english"
                                                                fluid id="name_in_english"
                                                                onChange={this.handleChange}
                                                                value={this.state.name_in_english}
                                                                 />
                                                        </div>
                                                        <br />
                                                        <div className="form-group">
                                                            <label className="label"
                                                                for="exampleFormControlTextarea1">
                                                                Book Description
                                                            </label><br />
                                                            <textarea dir="auto"
                                                                className="form-control"
                                                                id="exampleFormControlTextarea1"
                                                                rows="3" name="description"
                                                                fluid onChange={this.handleChange}
                                                                value={this.state.description} />
                                                        </div>
                                                        <br />

                                                        <div className="form-group">
                                                            <label className="label"
                                                                for="exampleFormControlTextarea1">
                                                                Book Description In English
                                                            </label><br />
                                                            <textarea dir="auto"
                                                                className="form-control"
                                                                id="exampleFormControlTextarea1"
                                                                rows="3" name="description_in_english"
                                                                fluid onChange={this.handleChange}
                                                                value={this.state.description_in_english} />
                                                        </div>
                                                        <br />
														
                                                        <label htmlFor="episode_series_id"
                                                            className="label">Select the Book Parent
                                                          <br />
                                                            <strong>Parent is : {this.state.selected_bookParent_name}</strong>
                                                        </label>
                                                        <br />
                                                        <Dropdown fluid search
                                                            selection
                                                            placeholder='Select Book Parent'
                                                            name="episode_series_id"
                                                            onChange={this.handleBookParentChange}
                                                            options={bookParentList} />
                                                        <br />
                                                        

                                                        {/* <label htmlFor="episode_series_id"
                                                            className="label">Select the Radio
                                                          <br />
                                                            <strong>Parent is : {this.state.selected_radio_name}</strong>
                                                        </label>
                                                        <br />
                                                        <Dropdown fluid search
                                                            selection
                                                            placeholder='Select Radio'
                                                            name="episode_series_id"
                                                            onChange={this.handleRadioChange}
                                                            options={radioList} /> */}
                                                        <br />


                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            <span className="required_input">*</span>
                                                            Select payment availability</label><br />

                                                        <Dropdown fluid selection
                                                            placeholder='Select Payment Availability'
                                                            id="episode_series_id"
                                                            onChange={this.handlePaymentAva}
                                                            options={is_free_type}
                                                            value={this.state.isFree}
                                                        />
                                                        <br />

                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            Sub Value</label><br />

                                                        <Dropdown fluid search selection
                                                            placeholder='Select Sub Value'
                                                            id="episode_series_id"
                                                            onChange={this.handleSubValueChange}
                                                            options={subValuesList}
                                                            value={this.state.subValueId}
                                                        />
                                                        <br />

                                                        {/* DataComponent */}
                                                        {/* {customPublishDate} */}
                                                        <PublishDate getPublishDateCallBack={this.getPublishDate.bind(this)}
                                                            date={this.state.publishDate}
                                                            showNow={!isInvalid} />
                                                        <br />
                                                        <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={this.state.featured}
                                                            onChange={this.handleCheckboxChange}
                                                        />
                                                        {' Featured'}
                                                        </label>
                                                        <br />
                                                        
<div class="row">
	<div class="col-3">
                              
							 <label htmlFor="episode_ageFrom_id" className="label" >                                
                                Select Age From
                              </label>
                              <br />

                              <Dropdown                                
                                fluid
                                selection
                                placeholder="Age From"
                                id="episode_ageFrom_id"
                                onChange={this.handleAgeFromSelection}
                                options={AgeFromArr}
                                value={this.state.ageFrom}
                              />
                              <br />                              
	</div>
	<div class="col-3">
                              
							 <label htmlFor="episode_ageTo_id" className="label" >                                
                                Select Age To
                              </label>
                              <br />

                              <Dropdown                                
                                fluid
                                selection
                                placeholder="Age To"
                                id="episode_ageTo_id"
                                onChange={this.handleAgeToSelection}
                                options={AgeFromArr}
                                value={this.state.ageTo}
                              />
                              <br />                              
	</div>	
</div> 
                                                            
<br/>                                                        

<label
                                htmlFor="episode_ContentVendor_id"
                                className="label"
                              >
                                
                                Select Content Vendor
                              </label>
                              <br />

                              <Dropdown                                
                                fluid
                                selection
                                placeholder="Select Content Vendor"
                                id="episode_ContentVendor_id"
                                onChange={this.handleContentVendorSelection}
                                options={ContentVendorsArr}
                                value={this.state.contentVendorSelectedId}
                              />
                              <br />
                              
                                                        <label htmlFor="name" className="label">
                                                            Title Audio
                                                        </label>
                                                        <UploadAudio
                                                            getAudioUrlCallBack={this.getTitleAudio.bind(this)}
                                                            audioPreview={this.state.titleAudioUrl}
                                                            audioUrl={this.state.titleAudioUrl}
                                                            s3DirectryrName="readWithJeel/audio"
                                                        />


                                                        <label htmlFor="name" className="label">
                                                            Book Audio
                                                        </label>
                                                        <UploadAudio
                                                            getAudioUrlCallBack={this.getBookAudio.bind(this)}
                                                            audioPreview={this.state.bookAudioUrl}
                                                            audioUrl={this.state.bookAudioUrl}
                                                            s3DirectryrName="readWithJeel/audio" />


                                                        {/* <label htmlFor="name" className="label">
                                                            Audio
                                                        </label>
                                                        <UploadAudio
                                                            getAudioUrlCallBack={this.getAudio.bind(this)}
                                                            audioPreview={this.state.voice}
                                                            audioUrl={this.state.voice}
                                                            s3DirectryrName="readWithJeel/audio"
                                                        /> */}
                                                        <br />
                                                        {fileLink}
                                                        {uploadFile}
                                                        <br />
                                                        <br />
                                                        <div className='w-100'>
                                                            <div className="book-pages mb-3" style={{ border: "1px solid #22242626", padding: "10px" }}>
                                                                <div className="pages-header d-flex justify-content-between align-items-center"
                                                                    style={{ borderBottom: "1px solid #22242626", marginBottom: "10px", paddingBottom: "10px" }}>
                                                                    <label htmlFor="song_name" className="label" style={{ fontWeight: "bold", textTransform: "uppercase", color: "#6f1a65" }}>
                                                                        Book Pages</label>
                                                                    {
                                                                        this.state.pages && this.state.pages.length ?
                                                                            <>
                                                                                {!this.state.isDragable ? <div>
                                                                                    <button
                                                                                        id="saveBookPageOrder"
                                                                                        type="button"
                                                                                        className="ui button"
                                                                                        onClick={() => {
                                                                                            this.saveAllPagesUpdate()
                                                                                        }}
                                                                                        style={{
                                                                                            float: "none", margin: 0, marginInlineStart:
                                                                                                "10px",
                                                                                            background: "purple",
                                                                                            color: "white"
                                                                                        }}
                                                                                        disabled={this.state.pageEdit && this.state.isDragable}
                                                                                    >Save</button>

                                                                                    <button
                                                                                        id="addBookPage"
                                                                                        type="button"
                                                                                        className="ui button"
                                                                                        style={{
                                                                                            float: "none", margin: 0, marginInlineStart:
                                                                                                "10px"
                                                                                        }}
                                                                                        onClick={(e) => {
                                                                                            this.toggleDrag(true);
                                                                                            this.cancelOrder();
                                                                                        }}>
                                                                                        Cancel</button>
                                                                                </div> : <button
                                                                                    disabled={this.state.pagesLoading ? true : false}
                                                                                    id="addBookPage"
                                                                                    type="button"
                                                                                    className="ui button primary  editPage"
                                                                                    style={{
                                                                                        float: "none", margin: 0, marginInlineStart:
                                                                                            "10px"
                                                                                    }}
                                                                                    onClick={(e) => { this.toggleDrag(false) }}>
                                                                                    Edit {this.state.pagesLoading ? 'Loading ...' : ''}</button>}


                                                                            </>


                                                                            : <button
                                                                                id="addBookPage"
                                                                                type="button"
                                                                                className="seriesButtons"
                                                                                style={{
                                                                                    float: "none", margin: 0, marginInlineStart:
                                                                                        "10px"
                                                                                }}
                                                                                onClick={(e) => { this.addBookPage(e); this.hanldeNewPage() }}>
                                                                                + Add Page</button>
                                                                    }

                                                                </div>
                                                                <div className='pages-list'>
                                                                    {this.bookPagesList()}
                                                                    {this.state.isDragable && this.newPagesList()}
                                                                </div>
                                                                {
                                                                    this.state.pages && this.state.pages.length && this.state.isDragable ? <button
                                                                        id="addBookPage"
                                                                        type="button"
                                                                        className="seriesButtons d-block"
                                                                        style={{
                                                                            float: "none", margin: 0, marginInlineStart:
                                                                                "auto"
                                                                        }}
                                                                        onClick={(e) => this.addBookPage(e)}>
                                                                        + Add Page</button> : ''
                                                                }


                                                            </div>
                                                        </div>





                                                        <br /> <br /> <br />
                                                    </form>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}


const mapStateToProps = state => ({

    readWithJeel: state.readWithJeel.item,
    activity: state.readWithJeel.item.activityId,
    auth: state.firebase.auth,

    addPageItem: state.readWithJeel.addPageItem,

    loading: state.readWithJeel.loading,
    success: state.readWithJeel.book_updated_successfully,
    error: state.readWithJeel.error_updated_book,

    allSubValues: state.ContentSubValue.items,
    redirectionPage: state.redirectionPage.item,
    // radio: state.radio.items,

    successPageUpdate: state.readWithJeel.updating_book_page_sucessfully,
    errorPageUpdate: state.readWithJeel.updating_book_page_error,
    errorDeletePage: state.readWithJeel.deleting_book_page_error,
    successDeletePage: state.readWithJeel.deleting_book_page_sucessfully,
    loadingPages: state.readWithJeel.loadingPages,

    addNewPageSuccess: state.readWithJeel.book_page_added_sucessfully,
    addNewPageError: state.readWithJeel.error_adding_book_page,
    bookParent: state.bookParent.items,
    ContentVendorsList : state.contentVendor.State_items,

});

EditBook.propTypes = {
    addBookItemWithSentFrom: propTypes.func.isRequired,
    fetchAllBookParent: propTypes.func.isRequired,
    getBookParentData: propTypes.func.isRequired,
}
export default connect(
    mapStateToProps,
    {
        fetchBooks,
        getBookData,
        emptyBook,
        updateBook,
        fetchAllSubValues,
        emptyError,

        // Page
        updateBookPage,
        addBookPage,
        deleteBookPage,
        addBookItemWithSentFrom,
        fetchAllBookParent,
        getBookParentData

        // fetchAllRadio,
        // getRadioData
    })(EditBook);
