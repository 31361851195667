import React, { useState } from "react";
import CustomInput from "../shared/CustomInput";
import { EmailIcon, PasswordIcon } from "../shared/Icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

const LoginForm = ({ handleEmail, handlePassword, handleLogin, loading }) => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <form>
      <div className="form-group">
        <label>البريد الإلكتروني</label>
        <CustomInput
          type="email"
          icon={<EmailIcon />}
          onChange={(e) => handleEmail(e)}
          placeholder="أدخل بريدك الإلكتروني"
        />
      </div>

      <div className="form-group">
        <label>كلمة المرور</label>
        <CustomInput
          type={showPassword ? "text" : "password"}
          icon={<PasswordIcon />}
          onChange={(e) => handlePassword(e)}
          isPassword={true}
          togglePassword={togglePassword}
          placeholder="أدخل كلمة المرور"
        />
      </div>
      <button
        className="btn btn-auth"
        onClick={(e) => {
          e.preventDefault();
          handleLogin();
        }}
        disabled={loading}
      >
        تسجيل الدخول
        {loading && (
          <FontAwesomeIcon icon={faSpinner} className="faTimes fa-spin mr-2" />
        )}
      </button>
      <div className="auth-note text-center mt-3">
        ليس لديك حساب؟{" "}
        <button
          className="auth-link"
          onClick={() => {
            history.push("/register");
          }}
        >
          سجل الآن
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
