import React from "react";
import { useDispatch } from "react-redux";

import CardComponent from "../../../images/cardComponent.png";
import { savePaymentMethod } from "../../../Actions/PaymentDataAction";
import { PRE_PAID_CARD } from "../constants";
import { useHistory } from "react-router-dom";

const PrePaidCard = ({ openLoginModal = () => false }) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(savePaymentMethod(PRE_PAID_CARD));
    // openLoginModal();
    history.push("/login");
  };

  return (
    <div
      className="styled-div-pre-paid-card custom-pre-paid"
      onClick={handleClick}
      style={{ cursor: "pointer" }}
    >
      <img style={{ marginLeft: "3px" }} src={CardComponent} alt="" />
      <p className="pre-paid-text">هل لديك بطاقة دفع مسبقة من جيل ؟</p>
    </div>
  );
};

export default PrePaidCard;
