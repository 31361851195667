import React from "react";

export const EmailIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.167 17.5832H5.83366C3.33366 17.5832 1.66699 16.3332 1.66699 13.4165V7.58317C1.66699 4.6665 3.33366 3.4165 5.83366 3.4165H14.167C16.667 3.4165 18.3337 4.6665 18.3337 7.58317V13.4165C18.3337 16.3332 16.667 17.5832 14.167 17.5832Z"
        stroke="#9B9B9D"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1663 8L11.558 10.0833C10.6997 10.7667 9.29134 10.7667 8.433 10.0833L5.83301 8"
        stroke="#9B9B9D"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PasswordIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 8.83317V7.1665C5 4.40817 5.83333 2.1665 10 2.1665C14.1667 2.1665 15 4.40817 15 7.1665V8.83317"
        stroke="#9B9B9D"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0003 15.9167C11.1509 15.9167 12.0837 14.9839 12.0837 13.8333C12.0837 12.6827 11.1509 11.75 10.0003 11.75C8.84973 11.75 7.91699 12.6827 7.91699 13.8333C7.91699 14.9839 8.84973 15.9167 10.0003 15.9167Z"
        stroke="#9B9B9D"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.167 18.8335H5.83366C2.50033 18.8335 1.66699 18.0002 1.66699 14.6668V13.0002C1.66699 9.66683 2.50033 8.8335 5.83366 8.8335H14.167C17.5003 8.8335 18.3337 9.66683 18.3337 13.0002V14.6668C18.3337 18.0002 17.5003 18.8335 14.167 18.8335Z"
        stroke="#9B9B9D"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
