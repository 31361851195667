import React, { useEffect, useState } from "react";
import AuthLayout from "../layout/AuthLayout";
import LoginForm from "./LoginForm";
import { connect } from "react-redux";
import { customerSignIn, empty } from "../../../../Actions/AuthActions";
import { signedInCustomer } from "../../../../Actions/CustomerActions";
import {
  PORTAL_PRE_PAID,
  PORTAL_UPGRADE_SUBSCRIPTION,
} from "../../../../routes";
import { PRE_PAID_CARD } from "../../constants";
import { useHistory } from "react-router-dom";

const LoginPage = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState(false);

  const { loginsucess, loginerror, loginloading, paymentMethodName } = props;

  useEffect(() => {
    if (loginsucess) {
      let redirect =
        paymentMethodName === PRE_PAID_CARD
          ? PORTAL_PRE_PAID
          : PORTAL_UPGRADE_SUBSCRIPTION;
      history.push(redirect);
    }
  }, [loginsucess]);

  useEffect(() => {
    if (
      loginsucess === false &&
      loginerror !== "" &&
      loginerror !== false &&
      loginerror !== undefined
    ) {
      if (loginerror.code === "auth/network-request-failed") {
        setErrorMsg("فشل الاتصال بالأنترنت، يرجى المحاولة لاحقاً");
      } else if (loginerror.code === "auth/user-not-found") {
        setErrorMsg(
          "البريد الإلكتروني المُدخل غير موجود، فضلًا أدخل عنوان بريد فعَّال"
        );
      } else if (loginerror.code === "auth/wrong-password") {
        setErrorMsg(
          "كلمة المرور المُدخلة غير صحيحة، برجاء التحقق من كلمة المرور"
        );
      } else if (loginerror.code === undefined) {
        setErrorMsg(loginerror);
      } else {
        setErrorMsg("حدث خطأ ما، يرجى المحاولة لاحقاً");
      }
      setError(true);
      props.empty();
    }
  }, [loginsucess, loginerror]);

  const handleData = () => {
    setErrorMsg("");
    setError(false);
  };

  const handleEmail = (e) => {
    handleData();
    const emailValue = e.target.value.replace(/\s\s+/g, " ");
    setEmail(emailValue);
  };

  const handlePassword = (e) => {
    handleData();
    const passwordValue = e.target.value.replace(/\s\s+/g, " ");
    setPassword(passwordValue);
  };

  const handleLoginSubmit = () => {
    let obj = {
      email,
      password,
    };
    props.signedInCustomer();
    props.customerSignIn(obj);
  };

  return (
    <AuthLayout>
      <div className="auth-card">
        <div className="mb-3">
          <div className="auth-title">مرحبــاً بعــودتك</div>
          <div className="auth-desc mt-3">
            يرجى إدخال بيانات الحساب لتسجيل الدخول
          </div>
        </div>
        <p style={{ color: "red", textAlign: "right" }}>{error && errorMsg}</p>
        <div className="auth-form">
          <LoginForm
            handleEmail={handleEmail}
            handlePassword={handlePassword}
            handleLogin={handleLoginSubmit}
            loading={loginloading}
          />
        </div>
      </div>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => ({
  loginsucess: state.authUser.success,
  loginerror: state.authUser.errorMsg,
  loginloading: state.authUser.loading,
  paymentMethodName: state.paymentData.paymentMethod,
});

export default connect(mapStateToProps, {
  customerSignIn,
  signedInCustomer,
  empty,
})(LoginPage);
