import React from "react";
import mainLogo from "../../../../images/portal/icons/logo.svg";
import { useHistory } from "react-router-dom";

const AuthNavbar = () => {
  const history = useHistory();

  return (
    <div className="auth-navbar d-flex align-items-center">
      <div className="container">
        <div className="auth-navbar-wrapper d-flex justify-content-between align-items-center">
          <div className="jeel-logo">
            <img src={mainLogo} alt="logo" />
          </div>
          <div className="jeel-links">
            <div className="jeel-home">
              <button
                className="btn btn-auth-home"
                onClick={() => {
                  history.push("/");
                }}
              >
                عودة للرئيسية
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthNavbar;
