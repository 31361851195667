import {
    FETCH_STORIES, LOAD_STORIES, ERROR_LOADING_STORIES, ADD_STORY, ERROR_ADDING_STORY
    , ERROR_ADDING_ACTIVITY, PUBLISH_ACTIVITY, PUBLISH_STORY, PUBLISH_VIDEO
    , ERROR_ADDING_VIDEO, EMPTY_STORY, LOAD_STORY, GET_STORY_DATA, ERROR_LOADING_STORY,
    STORY_UPDATED, STORY_UPDATE_FAIL, EMPTY_STORY_VIDEOS, SETTING_STORY_STATUS, ERROR_SETTING_STORY_STATUS,
    DELETE_STORY,
    ERROR_DELETING_STORY,
    ERROR_DELETING_STORY_PUZZLE,
    EMPTY_ALL_STORIES,ADD_Story_WITH_SENTFROM,
    FETCH_All_BOOK_PARENT, 
    //GET_BOOK_PARENT_DATA, LOAD_BOOK_PARENTES
    ERROR_LOADING_BOOK_PARENTES,
    GET_ALL_PARENTS,story_get_all_parent_error_story
} from './Types'
import axios, { post, put } from 'axios'
import { saveActivity } from '../Promises/Activity.js';
import { saveStory } from '../Promises/Story.js'
import firebase from 'firebase/app'

export const fetchStories = () => dispatch => {
    dispatch({
        type: LOAD_STORIES,
        payload: 'loading'
    })

    axios.get('/Story/getAllStoriesList')
        .then((res) => {
            dispatch({
                type: FETCH_STORIES,
                payload: res.data
            })
        })
        .catch((Error) => {
            dispatch({
                type: ERROR_LOADING_STORIES,
                payload: Error.message
            })
        })


}

export const addStoryItemWithSentFrom = (item, sentFrom) => dispatch => {
	
	console.log('In storyAction Dispath: '+JSON.stringify({id:item.id,name:item.name},null,2));
	
    const storyItem = {
        item: item,
        sentFrom: sentFrom
    }
    dispatch({
        type: ADD_Story_WITH_SENTFROM,
        payload: storyItem
    })
}


export const addStory = (Name, Description,
    Icon_Image, Publish_Date,
    Video_With_Music, Video_Without_Music,
    videoWithMusic_Duration,
    videoWithoutMusic_Duration,
    radiobutton, isFree, voice,
    subValueId,
    selected_storyParent_id,
    selected_storyParent_name,
    name_in_english,description_in_english,
    videoKeyVideoWithMusic,
    videoKeyVideoWithoutMusic,
    featured,contentVendorSelectedId,ageFrom,ageTo) => dispatch => {
	
	
	
        if (Description === null &&
            Description === undefined) {
            Description = '';
        }

        if (description_in_english === null ||
            description_in_english === undefined) {
            description_in_english = '';
        }

        if (name_in_english === null ||
            name_in_english === undefined) {
            name_in_english = '';
        }
        const story = {
            Name: Name,
            Description: Description,
            Icon_Image: Icon_Image,
            Publish_Date: Publish_Date,
            Video_With_Music: Video_With_Music,
            Video_Without_Music: Video_Without_Music,
            videoWithMusic_Duration: videoWithMusic_Duration,
            videoWithoutMusic_Duration: videoWithoutMusic_Duration,
            videoKeyVideoWithMusic :videoKeyVideoWithMusic,
            videoKeyVideoWithoutMusic :videoKeyVideoWithoutMusic,
            radiobutton: radiobutton,
            isFree: isFree,
            voice: voice,
            subValueId: subValueId,
            selected_storyParent_id : selected_storyParent_id,
            selected_storyParent_name:selected_storyParent_name,
            name_in_english: name_in_english,
            description_in_english:description_in_english,
            featured: featured,
            contentVendorSelectedId:contentVendorSelectedId,
            ageFrom:ageFrom,
            ageTo:ageTo,
        }
        dispatch({
            type: ADD_STORY,
            payload: story
        })
    }



export const getStoryData = (id) => {
	
	//console.log('in storyAction , getDataaaaa: '+id);
	
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_STORY,
                payload: 'loading'
            })
            const url = '/Story/getStoryDetails/admin';
            const formData = new FormData();
            formData.append('Story_ID', id);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {

                        type: GET_STORY_DATA,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_STORY,
                    payload: Error.message
                })
            })
        })



    }
}

export const publishStory = (name) => dispatch => {
	
	//console.log('in storyAction , publishStory'+name);
	
    dispatch({
        type: LOAD_STORY,
        payload: 'loading'
    })

    saveActivity(name).then((res) => {
        dispatch({
            type: PUBLISH_ACTIVITY,
            payload: res
        })
        saveStory(res.id).then((res) => {
            dispatch({
                type: PUBLISH_STORY,
                payload: res.data
            })

        }).catch((Error) => {
            dispatch({
                type: ERROR_ADDING_STORY,
                payload: Error.message
            })
        })
    }).catch((Error) => {
        dispatch({
            type: ERROR_ADDING_ACTIVITY,
            payload: Error.message
        })
    })

}

export const updateStory = (ID, Name, Description,
    Icon_Image, Publish_Date, Activity_ID,
    videoWithMusic,
    videoWithMusic_duration, videoWithoutMusic,
    videoWithoutMusic_duration, paymentAva, voice,
    subValueId,    
    name_in_english,
    description_in_english,
    video_key_video_with_music,
    video_key_video_without_music,
    featured,bookParentId,contentVendorSelectedId,ageFrom,ageTo,selected_storyParent_id) => {

	//console.log('in storyAction[updateStory]: '+bookParentId);

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            dispatch({
                type: LOAD_STORY,
                payload: 'loading'
            })

            if (Description === null ||
                Description === undefined) {
                Description = '';
            }

            if (description_in_english === null ||
                description_in_english === undefined) {
                    description_in_english = '';
            }
            if (name_in_english === null ||
                name_in_english === undefined) {
                    name_in_english = '';
            }

            let isFree = ''
            if (paymentAva === 'paid') {
                isFree = 0
            }
            else if (paymentAva === 'free') {
                isFree = 1
            }

            let updatedSubValueId
            if (subValueId === '' ||
                subValueId === undefined ||
                subValueId === null) {
                updatedSubValueId = 0
            } else {
                updatedSubValueId = subValueId
            }


            let videoWithMusicUrl = '', videoWithoutMusicUrl = '';
            let videoKeyVideoWithMusic ='' , videoKeyVideoWithoutMusic='';
            if (videoWithMusic === '' ||
                videoWithMusic === undefined ||
                videoWithMusic === null) {
                videoWithMusicUrl = ''
            } else {
                videoWithMusicUrl = videoWithMusic
            }


            if (videoWithoutMusic === '' ||
                videoWithoutMusic === undefined ||
                videoWithoutMusic === null) {
                videoWithoutMusicUrl = ''
            } else {
                videoWithoutMusicUrl = videoWithoutMusic
            }

            if (video_key_video_with_music === '' ||
            video_key_video_with_music === undefined ||
            video_key_video_with_music === null) {
                videoKeyVideoWithMusic = ''
        } else {
            videoKeyVideoWithMusic = video_key_video_with_music
        }
        if (video_key_video_without_music === '' ||
            video_key_video_without_music === undefined ||
            video_key_video_without_music === null) {
                videoKeyVideoWithoutMusic = ''
        } else {
            videoKeyVideoWithoutMusic = video_key_video_without_music
        }
            let status = '';
            if (Name === '' || Name === null || Name === undefined
                || Icon_Image === '' || Icon_Image === null || Icon_Image === undefined
                || videoWithoutMusic_duration == 0
                || videoWithoutMusicUrl === '' || videoWithoutMusicUrl === null || videoWithoutMusicUrl === undefined
                || videoWithMusic_duration == 0
                || videoWithMusicUrl === '' || videoWithMusicUrl === null || videoWithMusicUrl === undefined
                || Publish_Date === '' || Publish_Date === null) {
                status = 'SUSPENDED'
            }
            else {
                status = ''
            }
/*
console.log('Before updatetttt: ');
console.log('ID: '+ID);
console.log('Activity_ID: '+Activity_ID);
console.log('Status: '+status);
console.log('Is_free: '+isFree);
console.log('Content_Sub_Value_Id: '+updatedSubValueId);
console.log('bookParentId: '+bookParentId);
console.log('featured: '+featured);
*/

            const url = '/Story/updateStory';
            const formData = new FormData();
            formData.append('ID', ID);
            formData.append('Name', Name);
            formData.append('Description', Description);
            formData.append('Icon_Image', Icon_Image);
            formData.append('Publish_Date', Publish_Date);
            formData.append('Activity_ID', Activity_ID);
            formData.append('Video_With_Music_URL', videoWithMusicUrl);
            formData.append('Video_With_Music_Duration', videoWithMusic_duration);
            formData.append('Status', status)
            formData.append('Video_Without_Music_URL', videoWithoutMusicUrl);
            formData.append('Video_Without_Music_Duration', videoWithoutMusic_duration);
            formData.append('Is_free', isFree);
            formData.append('Voice', voice);
            formData.append('Content_Sub_Value_Id', updatedSubValueId);
            formData.append('Story_Parent_Id' , bookParentId);
            formData.append('Name_In_English', name_in_english);
            formData.append('Description_In_English', description_in_english);
            formData.append('Video_Key_Video_With_Music', videoKeyVideoWithMusic);
            formData.append('Video_Key_Video_Without_Music', videoKeyVideoWithoutMusic);
            formData.append('featured', featured);
            formData.append('contentVendorSelectedId', contentVendorSelectedId);
            formData.append('ageFrom', ageFrom);
            formData.append('ageTo', ageTo);
            formData.append('newParentId', selected_storyParent_id);
            
            
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            put(url, formData, config).then((res) => {
                dispatch({
                    type: STORY_UPDATED,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: STORY_UPDATE_FAIL,
                    payload: Error.message
                })
            })

        })
    }
}

export const emptyStory = () => dispatch => {
    dispatch({
        type: EMPTY_STORY,
        payload: ''
    })
}

export const emptyAllStories = () => dispatch => {
    dispatch({
        type: EMPTY_ALL_STORIES,
        payload: ""
    })
}

export const setStoryStatus = (id, status) => {
    return (dispatch, gtState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/Story/setStoryStatus"
            const formData = new FormData();
            formData.append("ID", id);
            formData.append("Status", status);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: SETTING_STORY_STATUS,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SETTING_STORY_STATUS,
                    payload: Error.response.data.message//Error.message
                })
            })

        })
    }
}

export const deleteStory = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/Story/deleteStory"
            const formData = new FormData();
            formData.append("Story_ID", id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: DELETE_STORY,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_STORY,
                    payload: Error.response.data.message
                })
            })
        })
    }
}



export const deleteStoryPuzzle = (id) => {

    const url = "/StoryPuzzleController/deleteStoryPuzzle"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Story_Puzzle_Id", id);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}



export const saveStoryPuzzle = (Story_ID, Image_URL) => {

    const url = "/StoryPuzzleController/saveStoryPuzzle"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Story_ID", Story_ID);
    formData.append("Image_URL", Image_URL);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}





export const deleteStoryPaintingGame = (id) => {

    const url = "/StoryPaintingGameController/deleteStoryPaintingGame"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Story_Painting_Game_Id", id);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const saveStoryPaintingGame = (Story_ID, Image_URL) => {

    const url = "/StoryPaintingGameController/saveStoryPaintingGame"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Story_ID", Story_ID);
    formData.append("Image_URL", Image_URL);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}


/*Start of Drag Drop*/ 

export const getDragAndDropStory = (DDID) => {
    const url = "/StoryDragDrop/getStoryDragAndDropData"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("StoryDragDrop_ID", DDID);
    return axios({
        method: "post",
        headers: {
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const saveStoryDragAndDrop = (attributes) => {
    const url = "/StoryDragDrop/saveStoryDragDrop"
    let token = firebase.auth().currentUser.h.b;
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/json',
            'X-Authorization-Firebase': token
        },
        data: {
            storyId: attributes.parentId, 
            imageUrl: attributes.game_Image,
            cropedImageUrl: attributes.cropedImageUrl,
            dragDropWidth: attributes.dragDropWidth,
            dragDropHeight: attributes.dragDropHeight,
            draggableElements: attributes.listElements
        },
        url: url
    });
}

export const deleteStoryDragAndDrop = (id) => {
    const url = "/StoryDragDrop/deleteDragAndDrop"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("StoryDragDrop_ID", id);
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const addDraggableElementStory = (DDID, draggableWidth, draggableHeight, xCoordinate, yCoordinate, draggableImage) => {
    const url = "/StoryDragDrop/addDraggableElement"
    let token = firebase.auth().currentUser.h.b;
    return axios({
        method: "post",
        headers: {
            'X-Authorization-Firebase': token
        },
        data: {
            dragDropId: DDID,
            draggableWidth: draggableWidth,
            draggableHeight: draggableHeight,
            xCoordinate: xCoordinate,
            yCoordinate: yCoordinate,
            draggableImage: draggableImage,
        },
        url: url
    });
}

export const deleteDraggableElementStory = (id) => {
    const url = "/StoryDragDrop/deleteDraggableElement"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("DraggableElement_ID", id);
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const updateOneDraggableElementStory = (id, width, height, XCoordinate, YCoordinate, imageURL) => {
    const url = "/StoryDragDrop/updateOneDraggableElement";
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("DraggableId", id);
    formData.append("DraggbleWidth", width);
    formData.append("DraggbleHeight", height);
    formData.append("XCoordinate", XCoordinate);
    formData.append("YCoordinate", YCoordinate);
    formData.append("url", imageURL);

    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const updateDragAndDropStory = (id, width, height, imageURL, croppedImageURL) => {
    const url = "/StoryDragDrop/updateStoryDragAndDrop";
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("DragDropWidth", width);
    formData.append("DragDropHeight", height);
    formData.append("url", imageURL);
    formData.append("Cropped_Image_Url", croppedImageURL);

    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}


export const fetchAllBookParent = () => dispatch => {

    /*dispatch({
        type: LOAD_BOOK_PARENTES,
        payload: 'loading'
    })*/

    axios.get('/BookParent/getAllBookParentList')
        .then((res) => {
	//console.log('story parent items :'+JSON.stringify(res.data,null,2));
            dispatch({
                type: GET_ALL_PARENTS,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: story_get_all_parent_error_story,
                payload: Error.message
            })
        })
}

/*End of Drag Drop*/ 
