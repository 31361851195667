import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import firebase from "./config/fbConfig";
import Store from "./Store";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";

/*default white background in main-style.css*/
let pathname = window.location.pathname.split("/");

//let path = window.location.pathname.includes('portal');

// console.log(window.location.pathname)
// if(path === true){
//   import('./css/main-style.css');
// }else{
/* importing  AdminPanel (blue) root*/
if (
  window.location.pathname === "/" ||
  window.location.pathname.includes("/login") ||
  window.location.pathname.includes("/register") ||
  window.location.pathname === "/otherLoginMethods" ||
  window.location.pathname === "/loggedInByPhone" ||
  window.location.pathname === "/phoneCode" ||
  window.location.pathname === "/forgetPassword" ||
  window.location.pathname === "/account" ||
  window.location.pathname === "/accountProfile" ||
  window.location.pathname === "/paymentSubscriptionHistory" ||
  window.location.pathname === "/subscription" ||
  window.location.pathname === "/upgrade-subscription" ||
  window.location.pathname === "/successful" ||
  window.location.pathname === "/complete-process" ||
  window.location.pathname === "/paymentMethods" ||
  window.location.pathname === "/creditCard" ||
  window.location.pathname === "/fawry" ||
  window.location.pathname === "/prePaidCard" ||
  window.location.pathname === "/changeLoginMethod" ||
  window.location.pathname === "/paymentResult" ||
  window.location.pathname === "/mada" ||
  window.location.pathname === "/FawryFailure" ||
  window.location.pathname === "/FawrySuccess/data" ||
  window.location.pathname === "/FawryResult" ||
  pathname[1] === "FitLogin" ||
  pathname[1] === "FitVCode" ||
  pathname[1] === "MWVCode" ||
  pathname[1] === "FitSuccess" ||
  window.location.pathname === "/TpayLogin" ||
  pathname[1] === "TpayPhoneCode" ||
  pathname[1] === "TpaySuccess" ||
  window.location.pathname === "/telecomLogin" ||
  window.location.pathname === "/mondiaLogin" ||
  pathname[1] === "telecomVCode" ||
  pathname[1] === "telecomSuccess" ||
  pathname[1] === "mondiaSuccess" ||
  window.location.pathname === "/FawryFailure"
) {
  import("./css/portal-style.css").then(() => {
    console.log("portal-style");
  });
  import("./css/portal/global.css").then(() => {
    console.log("global-style");
  });
  import("./css/portal/home.css").then(() => {
    console.log("home-style");
  });
  import("./css/portal/subscribe.css").then(() => {
    console.log("subscribe-style");
  });
  import("./css/portal/navbar.css").then(() => {
    console.log("navbar-style");
  });
  import("./css/portal/footer.css").then(() => {
    console.log("footer-style");
  });
} else if (
  window.location.pathname !== "/MobilePaymentLogin" &&
  window.location.pathname !== "/MobilePaymentOffer" &&
  window.location.pathname !== "/MobilePaymentSupport" &&
  window.location.pathname !== "/MobilePaymentHistory" &&
  window.location.pathname !== "/MobilePaymentStripeCheckoutParent" &&
  window.location.pathname !== "/FawrySuccess/data" &&
  window.location.pathname !== "/FawryFailure" &&
  window.location.pathname !== "/MobilePaymentError" &&
  pathname[1] !== "MobilePayment" &&
  pathname[1] !== "FawryMobilePayment" &&
  pathname[1] !== "MobilePaymentProfile" &&
  pathname[1] !== "MobilePaymentFeedback" &&
  pathname[1] !== "MobilePaymentSwitch"
) {
  import("./css/newRoot.css").then(() => {
    console.log("blue");
  });
} else {
  /* importing  AdminPanel (white) root*/

  import("./css/main-style.css").then(() => {
    console.log("white");
  });
}
//}
Sentry.init({
  dsn: "https://79fe460225558b3c9a2896e2fa14f8d5@o4508166259408896.ingest.us.sentry.io/4508245433647104",
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0, //  Capture 100% of the transactions

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "https://pay-preprod.jeelapp.com",
    "https://jeelapp.com",
  ],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
let unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
  ReactDOM.render(
    <Provider store={Store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );
  //() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();

  unsubscribe();
});

// If you want your app to work offline and load faster, you can change
// unregister
