import React, { Component } from 'react'
import { Redirect, Link } from "react-router-dom";
import { getStoryData, emptyStory, updateStory } from '../../Actions/StoryActions'
import OuterHeader from '../Layout/OuterHeader'
import Dashboard from '../Dashboard'
import InnerHeader from '../Layout/InnerHeader'
import NavBar from '../Layout/NavBar'
import { Dropdown, Input, Button, Icon } from 'semantic-ui-react'
import UploadImageWithoutDropZone from '../S3Uploading/UploadImageWithoutDropZone';
import UploadVideoWithoutDropZone from '../S3Uploading/UploadVideoWithoutDropZone'
import { connect } from 'react-redux'
import Footer from '../Layout/Footer'
import PublishDate from '../Layout/PublishDate'
import UploadAudio from '../S3Uploading/UploadAudio';
import RenderVideoWithMusic from '../VideoPreview/RenderVideoWithMusic';
import RenderVideoWithoutMusic from '../VideoPreview/RenderVideoWithoutMusic';
import { STORY_FOLDER_NAME } from '../../portal/src/constants';

/*Action*/
import { fetchAllSubValues } from '../../Actions/ContentSubValueAction';
import {
    fetchAllStoryParent,
    getStoryParentData
} from '../../Actions/StoryParentAction'
/* Routes */
import { SIGN_IN, ALL_STORIES,VIEW_BOOK_PARENT } from '../../routes';
import VideoWithMusicUploader from '../FilePondUploading/VideoWithMusicUploader';
import VideoWithoutMusicUploader from '../FilePondUploading/VideoWithoutMusicUploader';
import { fetchAllContentVendors } from '../../Actions/ContentVendorActions.js'
import {fetchAllBookParent} from '../../Actions/StoryActions';

const is_free_type = [{
    key: 0,
    text: 'paid',
    value: 'paid'
},
{
    key: 1,
    text: 'free',
    value: 'free'
}]


const INITIAL_STATE =
{
    
    selected_storyParent_id: "",
    selected_storyParent_name: "",

    video_with_music_folder_id : "",
    video_with_out_music_folder_id : "",
    id: null,
    storyName: '',
    publishDate: '',
    videoWithMusic: '',
    videoWithoutMusic: '',
    activityId: '',
    iconImage: '',
    videoWithMusicDuration: 0,
    videoWithoutMusicDuration: 0,
    descreption: '',
    isFree: '',
    voice: '',
    subValueId: '',
    name_in_english: '',
    description_in_english:'',
    video_key_story_video_with_music: "",
    video_key_story_video_without_music: "",
    formErrors: { general: '' },
    featured: false,
    contentVendorSelectedId: '',
    ageFrom:'',
    ageTo:'',    
}

class EditStory extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
	
	this.props.fetchAllBookParent();
	
	//console.log(' in edit story mount : '+JSON.stringify(this.props,null,2));
	//console.log('Locationnnn: '+JSON.stringify(this.props.location,null,2));
	
	
	
        const { auth } = this.props;
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {
	
			const { item:story } = this.props.story;
	
			let bookParentId =''
			let storyIdFromDetails = null
			if(this.props.location.state)
			{
			 	bookParentId = this.props.location.state.bookParentId;
			 	storyIdFromDetails=this.props.location.state.storyIdFromDetails;
			}
 		
 		//console.log('bookParentId :'+bookParentId);
 		
            this.props.fetchAllSubValues();
            this.props.fetchAllStoryParent();

            let storyParentName = this.props.match.params.storyParentName;
            //let storyParentId = this.props.match.params.storyParentId;
            //let storyId = this.props.match.params.storyId;
            //console.log('Checking storyIdfromDetails :'+storyIdFromDetails);
            let storyId=null ;
            if(storyIdFromDetails)
            	storyId = storyIdFromDetails;
            else            
            	storyId = story.id;
			//console.log('storyId :'+storyId);

            this.setState({
                id: storyId,
                bookParentId: bookParentId,
                selected_storyParent_name: storyParentName,
                
            })
            this.props.getStoryData(storyId);
            this.props.getStoryParentData(bookParentId);

            const {storyParent} = this.props;
            //console.log('storyParent :'+storyParent.id);

            
            if (!storyParent.withMusicFolderId || storyParent.withMusicFolderId=== null ||storyParent.withMusicFolderId ==="" ||
      !storyParent.withoutMusicFolderId || storyParent.withoutMusicFolderId=== null ||storyParent.withoutMusicFolderId ===""){
        this.setState({
            video_with_music_folder_id : "",
            video_with_out_music_folder_id :""    ,
        })
      }
      else {
        this.setState({
            video_with_music_folder_id : storyParent.withMusicFolderId,
            video_with_out_music_folder_id :storyParent.withoutMusicFolderId    ,
        })
      }
        }

    }

    componentWillReceiveProps(nextprop) {
        
        //console.log('in Edit Story , Next :'+JSON.stringify(nextprop,null,2));
        
        let story = nextprop.story;
        
        if (story.videoWithMusicId !== undefined
            && story.videoWithoutMusicId !== undefined) {


            let videoMusicUrl = null,
                videoWithoutMusicUrl = null,
                videoMusicDuration = 0,
                videoWithoutMusicDuration = 0,
                videoKeyWithMusic = null,
                videoKeyWithoutMusic = null;

            if (story.videoWithMusicId !== undefined
                && story.videoWithMusicId !== null) {


                if (story.videoWithMusicId.url !== undefined
                    && story.videoWithMusicId.url !== null) {
                    videoMusicUrl = story.videoWithMusicId.url
                }

                if (story.videoWithMusicId.duration !== null
                    && story.videoWithMusicId.duration !== undefined) {
                    videoMusicDuration = story.videoWithMusicId.duration
                }
                if (story.videoWithMusicId.vdocipherId !== null
                    && story.videoWithMusicId.vdocipherId !== undefined) {
                        videoKeyWithMusic = story.videoWithMusicId.vdocipherId
                }
            }

            if (story.videoWithoutMusicId !== undefined
                && story.videoWithoutMusicId !== null) {


                if (story.videoWithoutMusicId.url !== undefined
                    && story.videoWithoutMusicId.url !== null) {
                    videoWithoutMusicUrl = story.videoWithoutMusicId.url
                }


                if (story.videoWithoutMusicId.duration !== null
                    && story.videoWithoutMusicId.duration !== undefined) {
                    videoWithoutMusicDuration = story.videoWithoutMusicId.duration
                }
                if (story.videoWithoutMusicId.vdocipherId !== null
                    && story.videoWithoutMusicId.vdocipherId !== undefined) {
                        videoKeyWithoutMusic = story.videoWithoutMusicId.vdocipherId
                }
            }

            let paymentAvailability = ''
            if (story.isFree == 0) {
                paymentAvailability = 'paid'
            }
            else if (story.isFree == 1) {
                paymentAvailability = 'free'
            }


            let audio = story.voice;
            //console.log(audio)
            if (audio === 'undefined') {
                audio = null
            }

            let subvalue = ''
            if (story.contentSubValue === null ||
                story.contentSubValue === undefined) {
                subvalue = ''
            } else {
                subvalue = story.contentSubValue.id
            }

            this.setState({
				id:story.id,
                storyName: story.name,
                iconImage: story.iconImage,
                descreption: story.description,
                videoWithMusic: videoMusicUrl,
                videoWithoutMusic: videoWithoutMusicUrl,
                publishDate: story.publishDate,
                activityId: story.activityId.id,
                videoWithMusicDuration: videoMusicDuration,
                videoWithoutMusicDuration: videoWithoutMusicDuration,
                isFree: paymentAvailability,
                voice: audio,
                subValueId: subvalue,
                name_in_english: story.nameInEnglish,
                description_in_english: story.descriptionInEnglish,
                video_key_story_video_with_music: videoKeyWithMusic,
                video_key_story_video_without_music: videoKeyWithoutMusic,
                featured: story.featured,
                bookParentId:story.parentID,
                contentVendorSelectedId: story.contentVendor?story.contentVendor.id:'',
                ageFrom:story.ageFrom,
    			ageTo:story.ageTo,
            })
            
            //console.log('storyId :::::::::::: '+story.id);
            
            let generalValidation = this.state.formErrors
            // if (story.nameInEnglish !== null && story.nameInEnglish !== undefined ) {
            //     generalValidation.general = '';
            //     this.setState({ formErrors: generalValidation });
            // }else {
            //     generalValidation.general = "Empty Data is not accepted";
            //     this.setState({ formErrors: generalValidation });
            // }
        }
    }


    getIconImageUrl(imageUrl) {
        if (imageUrl) {
            this.setState({ iconImage: imageUrl })
        }
    }

    getPublishDate(publishDate) {

        if (publishDate) {
            this.setState({ publishDate: publishDate });
        }
    }

    getVideoWithMusicUrl(videoUrl, duration) {
        if (videoUrl) {
            console.log(videoUrl)
            this.setState({
                videoWithMusic: videoUrl,
                videoWithMusicDuration: duration
            })
        }
    }
    getVideoWithoutMusicUrl(videoUrl, duration) {
        if (videoUrl) {
            this.setState({
                videoWithoutMusic: videoUrl,
                videoWithoutMusicDuration: duration
            })
        }
    }

    getAudio(audioUrl) {
        if (audioUrl) {

            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {

                this.setState({ voice: '' });
                console.log("audio URL in EditStory  if = "
                    + this.state.voice);

            }

            // else send the url to DB
            else {
                this.setState({ voice: audioUrl });
                console.log("audio URL in EditStory else = "
                    + this.state.voice);

            }

        }
    }

handleAgeFromSelection = (e, { value }) => {
       this.setState({ ageFrom: value })
    }
handleAgeToSelection = (e, { value }) => {
       this.setState({ ageTo: value })
    }
    
    handleChange = (e) => {
        let generalValidation = this.state.formErrors;

        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        const specialCharacters = /[@#$%^&*()\+={}[\]:;<>,\/|\\]/; 
        this.setState({ [e.target.name]: checkSpaces });
        let nameOfState = e.target.name;

        setTimeout(() => {

            if (specialCharacters.test(this.state.storyName) || specialCharacters.test(this.state.name_in_english)){
                generalValidation.general = "Story name can't contain spaecial characters";
                this.setState({ formErrors: generalValidation });
            }
            else if (checkSpaces === '' || checkSpaces === null || checkSpaces === ' ') {
                generalValidation.general = "Empty Data is not accepted";
                this.setState({ formErrors: generalValidation });
            }
            else {
                generalValidation.general = '';
                this.setState({ formErrors: generalValidation });
            }

            if ((this.state.iconImage === '' || this.state.iconImage === null || this.state.iconImage === undefined)
                || (this.state.storyName === '' || this.state.storyName === null || this.state.storyName === ' ')
                || this.state.description_in_english.length > 255
                // || (this.state.name_in_english === '' || this.state.name_in_english === null || this.state.name_in_english === ' ')
                // || (this.state.videoWithMusic == undefined || this.state.videoWithMusic == '')
                // || (this.state.videoWithoutMusic == undefined || this.state.videoWithoutMusic == '')
                // || (this.state.videoWithMusicDuration == 0 || this.state.videoWithMusicDuration == undefined)
                // || (this.state.videoWithoutMusicDuration == 0 || this.state.videoWithoutMusicDuration == undefined)
            ) {
                {
                    generalValidation.general = nameOfState === "description_in_english" ? 
                    "Description in English is too long (greater than 255 characters)." : 
                    "Empty Data is not accepted" ;
                    this.setState({ formErrors: generalValidation });
                }
            }

        }, 0);
    }

    handlePaymentAva = (e, { value }) => {

        console.log("value in handle change = " + value)

        this.setState({
            isFree: value
        })

    }

    updateStory(id, name, iconImage, publishDate,
        videoWithMusic, videoWithoutMusic,
        activityId, descreption,
        videoWithMusicDuration, videoWithoutMusicDuration,
        isFree, voice,
        subValueId,name_in_english, description_in_english,
        video_key_story_video_with_music,
        video_key_story_video_without_music, featured,bookParentId,contentVendorSelectedId,ageFrom,ageTo,selected_storyParent_id) {

//console.log(' update : , parent : '+bookParentId);

        this.props.updateStory(id, name, descreption,
            iconImage, publishDate,
            activityId, videoWithMusic,
            videoWithMusicDuration, videoWithoutMusic,
            videoWithoutMusicDuration, isFree, voice,
            subValueId, name_in_english, description_in_english,
            video_key_story_video_with_music,
            video_key_story_video_without_music, featured,bookParentId,contentVendorSelectedId,ageFrom,ageTo,selected_storyParent_id)
    }


    handleSubValueChange = (e, { value }) => {
        this.setState({ subValueId: value });
    };
    handleUploadVideoWithMusic = (uploadInfo) => {
        if (
          uploadInfo &&
          uploadInfo !== undefined &&
          uploadInfo !== null &&
          Object.keys(uploadInfo).length > 0
        ) {
          this.setState({ video_key_story_video_with_music: uploadInfo.videoId });
        } else {
          this.setState({ video_key_story_video_with_music: this.state.video_key_story_video_with_music });
        }
      };
      handleUploadVideoWithoutMusic = (uploadInfo) => {
        if (
          uploadInfo &&
          uploadInfo !== undefined &&
          uploadInfo !== null &&
          Object.keys(uploadInfo).length > 0
        ) {
          this.setState({ video_key_story_video_without_music: uploadInfo.videoId });
        } else {
            this.setState({ video_key_story_video_without_music: this.state.video_key_story_video_without_music });
          }
      };
    handleChangeVideoWithMusic = (uploadInfo) => {
        console.log("Received uploadInfo in parent:", uploadInfo);
        if (
          uploadInfo &&
          uploadInfo !== undefined &&
          uploadInfo !== null &&
          Object.keys(uploadInfo).length > 0
        ) {
          this.setState({ video_key_story_video_with_music: uploadInfo.videoId });
        } 

        else {
            this.setState({ video_key_story_video_with_music: this.state.video_key_story_video_with_music });
          }
      };
      handleChangeVideoWithoutMusic = (uploadInfo) => {
        console.log("Received uploadInfo in parent:", uploadInfo);
        if (
          uploadInfo &&
          uploadInfo !== undefined &&
          uploadInfo !== null &&
          Object.keys(uploadInfo).length > 0
        ) {
          this.setState({ video_key_story_video_without_music: uploadInfo.videoId });
        } 
        else {
            this.setState({ video_key_story_video_without_music: this.state.video_key_story_video_without_music });
          }
      };
      setVideoWithMusicReady = (isReady) => {
        console.log(this.state.videoKeyVideoWithMusic);
        if (
         isReady
        )
          this.setState({ VideoWithMusicReady: true });
      };
      setVideoWithoutMusicReady = (isReady) => {
        console.log(this.state.videoKeyVideoWithoutMusic);
        if (
         isReady
        )
          this.setState({ VideoWithoutMusicReady: true });
      };
    handleSubmit = (e) => {
        const { id, storyName, iconImage, publishDate,
            videoWithMusic, videoWithoutMusic,
            activityId, videoWithMusicDuration,
            videoWithoutMusicDuration, descreption,
            isFree, voice, subValueId, name_in_english, description_in_english,
            video_key_story_video_with_music,
        video_key_story_video_without_music, featured,contentVendorSelectedId,ageFrom,ageTo,selected_storyParent_id } = this.state;

	if(ageFrom){
		if(ageTo && ageTo< ageFrom){
			alert('Please Enter AgeTo greater than ageFrom')
			return false;	
		}
	}
	
//console.log('in submit,  , id: '+id);
			let bookParentId =''
			if(this.props.location.state)
			 	bookParentId = this.props.location.state.bookParentId;
 		
 		//console.log('bookParentId :'+bookParentId);
 		

        if (e.nativeEvent.submitter.id === "nextButton") {

            this.updateStory(id, storyName, iconImage,
                publishDate, videoWithMusic, videoWithoutMusic,
                activityId, descreption,
                videoWithMusicDuration,
                videoWithoutMusicDuration, isFree, voice,
                subValueId, name_in_english, description_in_english,
                video_key_story_video_with_music,
            video_key_story_video_without_music, featured,bookParentId,contentVendorSelectedId,ageFrom,ageTo,selected_storyParent_id);
        }

        /// calling save
        e.preventDefault();
    }

    handleStoryParentChange = (e, { value }) => {

        this.setState({ selected_storyParent_id: value[0].id })
        this.setState({ selected_storyParent_name: value[1].name })
    }
    handleCheckboxChange = () => {
        this.setState({ featured: !this.state.featured })
      };

    handleContentVendorSelection = (e, { value }) => {
        //console.log("contentVendor selected: " + value);
        this.setState({ contentVendorSelectedId: value })
    }
    
    render() {

        const { allSubValues } = this.props;
        const subValuesList = [];
        for (let i = 0; i < allSubValues.length; i++) {
            subValuesList.push({
                key: allSubValues[i].id,
                text: allSubValues[i].contentValue,
                value: allSubValues[i].id
            });
        }
        
// --------------------------------
    const { ContentVendorsList } = this.props;
    const ContentVendorsArr = [];
    
    //console.log('ContentVendorsList : '+JSON.stringify(ContentVendorsList,null,2));
    
    for (let i = 0; i < ContentVendorsList.length; i++) 
    {
      ContentVendorsArr.push({
        key: ContentVendorsList[i].id,
        text: ContentVendorsList[i].user.name,
        value: ContentVendorsList[i].id,
      });
    }
// --------------------------------        
// --------------------------------
const AgeFromArr = [];

for (let i = 1; i <= 10; i++) 
    {
      AgeFromArr.push({
        key: i,
        text: i,
        value: i,
      });
    }
// --------------------------------
           // for StoryParent dropdown 
           const { storyParent } = this.props;

           const storyParentList = [];
           for (let i = 0; i < storyParent.length; i++) {
            storyParentList.push({
                   key: storyParent[i].id,
                   text: storyParent[i].name,
                   value: [{ id: storyParent[i].id }, { name: storyParent[i].name }]
               })
           }
   
   
   const { parent_items } = this.props;
// --------------------------------
const storyParentList2 = [];
if(parent_items&&parent_items.length>0){
           for (let i = 0; i < parent_items.length; i++) {
            storyParentList2.push({
                   key: parent_items[i].id,
                   text: parent_items[i].name,
                   value: [{ id: parent_items[i].id }, { name: parent_items[i].name }]
               })
           }
}
// --------------------------------
           
        const { story_updated_successfully, error_editing_story } = this.props

        const { loading } = this.props;

        // loading button
        let $buttonSubmitLoader = (
            <Button disabled={this.state.formErrors.general}
                type="submit" id="nextButton"
            >
                Save
            </Button>);

        if (loading === true) {
            $buttonSubmitLoader = (
                <button id="nextButton" class="ui loading button">Loading</button>);
            console.log("loading");
        }


        if (story_updated_successfully === true) {
            alert("story updated successfully");
            this.props.emptyStory();
          
            const { redirectionPage } = this.props;

            this.props.getStoryParentData(redirectionPage.series_Id);

            var pageName =  redirectionPage.pageName
            //console.log('PAGE NAME: '+JSON.stringify(this.props,null,2));
            //return (<Redirect to={pageName} ></Redirect>)
            return (<Redirect to={{pathname:VIEW_BOOK_PARENT,state: {toTab:'STORY'}}}></Redirect>)
        
        }
        else if (story_updated_successfully === false) {
            alert("error missing data or network error" + error_editing_story);
            this.props.emptyStory();
        }

        const { storyName, iconImage, publishDate,
            videoWithMusic, videoWithoutMusic,
            videoWithMusicDuration,
            videoWithoutMusicDuration,
            name_in_english,
            isFree } = this.state;

        const isInvalid =
            storyName === '' || storyName == undefined || 
            publishDate === '' || publishDate == undefined ||
            iconImage === '' || iconImage == undefined ||
            videoWithMusic === '' || videoWithMusic == undefined ||
            videoWithoutMusic === '' || videoWithoutMusic == undefined ||
            videoWithMusicDuration === 0 || videoWithMusicDuration == undefined ||
            videoWithMusicDuration === '' ||
            videoWithoutMusicDuration === 0 || videoWithoutMusicDuration == undefined ||
            videoWithoutMusicDuration === '' ||
            isFree === '' || isFree == undefined;


        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <div className="container">
                                                    <h2 className="label">Edit Story</h2>
                                                    <br />
                                                    <form onSubmit={this.handleSubmit}>
                                                        <p style={{ color: "red" }}>{this.state.formErrors.general} </p>
                                                        <div className="input-field">
                                                            <label htmlFor="storyName" className="label">
                                                            <span className="required_input">*</span>
                                                                Story Name</label><br />
                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                type="text" name="storyName"
                                                                fluid id="storyName"
                                                                onChange={this.handleChange}
                                                                value={this.state.storyName} />
                                                        </div>
                                                        <br />

                                                        <div className="input-field">
                                                            <label htmlFor="name_in_english" className="label">Story Name In English</label><br />
                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                type="text" name="name_in_english"
                                                                fluid id="name_in_english"
                                                                onChange={this.handleChange}
                                                                value={this.state.name_in_english} />
                                                        </div>
                                                        <br />
                                                       
                                                        <label htmlFor="episode_series_id"
                                                            className="label">Select the Story Parent
                                                          <br />
                                                            <strong>Parent is : {this.state.selected_storyParent_name}</strong>
                                                        </label>
                                                        <br />
                                                        <Dropdown fluid search
                                                            selection
                                                            placeholder='Select Story Parent'
                                                            name="episode_series_id"
                                                            onChange={this.handleStoryParentChange}
                                                            options={storyParentList2} /> 
                                                        <br />
                                                        

                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            <span className="required_input">*</span>
                                                            Select payment availability</label><br />

                                                        <Dropdown fluid selection
                                                            placeholder='Select Payment Availability'
                                                            id="episode_series_id"
                                                            onChange={this.handlePaymentAva}
                                                            options={is_free_type}
                                                            value={this.state.isFree}
                                                        />
                                                        <br />

                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            Sub Value</label><br />

                                                        <Dropdown fluid search selection
                                                            placeholder='Select Sub Value'
                                                            id="episode_series_id"
                                                            onChange={this.handleSubValueChange}
                                                            options={subValuesList}
                                                            value={this.state.subValueId}
                                                        />
                                                        <br />


                                                        {/* DataComponent */}
                                                        <PublishDate
                                                            getPublishDateCallBack={this.getPublishDate.bind(this)}
                                                            date={this.state.publishDate}
                                                            showNow={!isInvalid} />

                                                        <br />
                                                        <label htmlFor="name" className="label">
                                                            Audio
                                                        </label>

                                                        <UploadAudio
                                                            getAudioUrlCallBack={this.getAudio.bind(this)}

                                                            audioPreview={this.state.voice}
                                                            audioUrl={this.state.voice}
                                                            s3DirectryrName="story/audio" />


                                                        <br />
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                checked={this.state.featured}
                                                                onChange={this.handleCheckboxChange}
                                                            />
                                                            {' Featured'}
                                                        </label>
                                                        <br />
                                                        
<div class="row">
	<div class="col-3">
                              
							 <label htmlFor="episode_ageFrom_id" className="label" >                                
                                Select Age From
                              </label>
                              <br />

                              <Dropdown                                
                                fluid
                                selection
                                placeholder="Age From"
                                id="episode_ageFrom_id"
                                onChange={this.handleAgeFromSelection}
                                options={AgeFromArr}
                                value={this.state.ageFrom}
                              />
                              <br />                              
	</div>
	<div class="col-3">
                              
							 <label htmlFor="episode_ageTo_id" className="label" >                                
                                Select Age To
                              </label>
                              <br />

                              <Dropdown                                
                                fluid
                                selection
                                placeholder="Age To"
                                id="episode_ageTo_id"
                                onChange={this.handleAgeToSelection}
                                options={AgeFromArr}
                                value={this.state.ageTo}
                              />
                              <br />                              
	</div>	
</div> 
                                                            
<br/>                                                        
                                                        
                                                        
<label
                                htmlFor="episode_ContentVendor_id"
                                className="label"
                              >
                                
                                Select Content Vendor
                              </label>
                              <br />

                              <Dropdown                                
                                fluid
                                selection
                                placeholder="Select Content Vendor"
                                id="episode_ContentVendor_id"
                                onChange={this.handleContentVendorSelection}
                                options={ContentVendorsArr}
                                value={this.state.contentVendorSelectedId}
                              />
                              <br />
                                                                                      
                                                        <label htmlFor="story_image" className="label">Story Image</label><br />

                                                        <div className="row">

                                                            <div className=" col-4">
                                                                <UploadImageWithoutDropZone
                                                                    getImageURLCallBack={this.getIconImageUrl.bind(this)}
                                                                    imageUrl={this.state.iconImage}
                                                                    imagepreview={this.state.iconImage}
                                                                    s3DirectryrName='story' />

                                                            </div>
                                                        </div>

                                                        <br />
                                                        <label className="label">Video with music</label>
 <div>
                            <RenderVideoWithMusic
                                  videoKey={this.state.video_key_story_video_with_music}
                                  id="VideoWithMusic"
                                  foldername={this.state.video_with_music_folder_id}
                                  changedid={this.handleChangeVideoWithMusic}
                                  enableEdit={ true}
                                  ></RenderVideoWithMusic>
                            </div>
                           
                           
                            <br />
                            <label className="label">Video without music</label>
                             <div>
                             <RenderVideoWithoutMusic
                                   videoKey={this.state.video_key_story_video_without_music}
                                   id="VideoWithoutMusic"
                                   foldername={this.state.video_with_out_music_folder_id}
                                   changedid={this.handleChangeVideoWithoutMusic}
                                   enableEdit={true}
                                   ></RenderVideoWithoutMusic>
                             </div>
                     
                           
                            <br />


                                                        {/* <label htmlFor="videoWithMusic" className="label">Video with music</label><br />

                                                        <div className="imgPreview">
                                                            <UploadVideoWithoutDropZone
                                                                getVideoURLCallBack={this.getVideoWithMusicUrl.bind(this)}
                                                                videoUrl={this.state.videoWithMusic}
                                                                videoPreview={this.state.videoWithMusic}
                                                                s3DirectryrName='story' />
                                                        </div>
                                                        <br />

                                                        <br />
                                                        <label htmlFor="videoWithMusic_duration" className="label">Video with music duration in seconds</label><br />
                                                        <Input fluid disabled
                                                            type="number" min="0" step="0.01"
                                                            name="videoWithMusicDuration"
                                                            id="videoWithMusicDuration"
                                                            onChange={this.handleChange}
                                                            value={this.state.videoWithMusicDuration} />

                                                        <br /><br /> <br />

                                                        <div className="input-field">
                                                            <label htmlFor="videoWithoutMusic" className="label">video without music</label><br />
                                                            <div className="imgPreview">
                                                                <UploadVideoWithoutDropZone
                                                                    getVideoURLCallBack={this.getVideoWithoutMusicUrl.bind(this)}
                                                                    videoPreview={this.state.videoWithoutMusic}
                                                                    videoUrl={this.state.videoWithoutMusic}
                                                                    s3DirectryrName='story' />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <br />
                                                        <label htmlFor="videoWithoutMusic_duration" className="label">Video without music duration in seconds</label><br />
                                                        <Input fluid disabled
                                                            type="number" min="0" step="0.01"
                                                            name="videoWithoutMusicDuration"
                                                            id="videoWithoutMusicDuration"
                                                            onChange={this.handleChange}
                                                            value={this.state.videoWithoutMusicDuration} />
                                                        <br />
 */}

                                                        <div className="form-group">
                                                            <label className="label" for="exampleFormControlTextarea1">Description</label>
                                                            <textarea dir="auto"
                                                                className="form-control"
                                                                id="exampleFormControlTextarea1"
                                                                rows="3" name="descreption"
                                                                onChange={this.handleChange}
                                                                value={this.state.descreption}></textarea>
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="label" for="exampleFormControlTextarea1">Description In English</label>
                                                            <textarea dir="auto"
                                                                className="form-control"
                                                                id="exampleFormControlTextarea1"
                                                                rows="3" name="description_in_english"
                                                                onChange={this.handleChange}
                                                                value={this.state.description_in_english}></textarea>
                                                        </div>

                                                        <br /> <br /> <br />

                                                        {$buttonSubmitLoader}

                                                        <br /> <br /> <br />
                                                    </form>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }


}
const mapStateToProps = state => ({
    loading: state.stories.loading,

    auth: state.firebase.auth,
    story: state.stories.item,
    story_updated_successfully: state.stories.story_updated_successfully,
    error_editing_story: state.stories.error_editing_story,
    allSubValues: state.ContentSubValue.items,
    redirectionPage: state.redirectionPage.item,
    storyParent: state.storyParent.item,
    ContentVendorsList : state.contentVendor.State_items,
	parent_items: state.stories.parent_items,

})
export default
    connect(
        mapStateToProps,
        {
            emptyStory,
            getStoryData,
            getStoryParentData,
            updateStory,
            fetchAllSubValues,
            fetchAllStoryParent,
            fetchAllBookParent,
            getStoryParentData

        })(EditStory)
