import React, { useState, useEffect } from "react";
import CustomInput from "../shared/CustomInput";
import { EmailIcon, PasswordIcon } from "../shared/Icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const SignupForm = ({
  handleEmail,
  handlePassword,
  handlePhoneNumber,
  handleConfirmPassword,
  handleConfirmPhoneNumber,
  handleRegisterSubmit,
  loading,
  confirmForm,
  phoneNumber,
  country,
  setCountry,
  confirmPassword,
  email,
  password,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(false);
  const [checkConfirmPassword, setCheckConfirmPassword] = useState(false);
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [strongPasswordError, setStrongPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    getCurrentCountryFromUrl();
  }, []);

  useEffect(() => {
    const e = { target: { value: confirmPasswordValue } };
    handleConfirmPasswordInput(e);
  }, [password]);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const getCurrentCountryFromUrl = () => {
    const query = new URLSearchParams(location.search);
    const codetest = query.get("x-test-country-code-x");
    if (codetest != null || codetest != undefined) {
      setCountry(codetest);
    }
  };

  const handleChangeNumber = (val) => {
    if (!isValidPhoneNumber(val)) {
      setPhoneError("رقم الهاتف غير صحيح");
      handleConfirmPhoneNumber(false);
    }

    if (isValidPhoneNumber(val)) {
      setPhoneError("");
      handleConfirmPhoneNumber(true);
    }

    if (!val) {
      setPhoneError("رقم الهاتف مطلوب");
    }
    handlePhoneNumber(val);
  };

  const handleChange = (e) => {
    handleEmail(e);
    setEmailError("");

    if (e.target.value.length === 0) {
      setEmailError("البريد الإلكتروني مطلوب");
    }
  };

  const handleConfirmPasswordInput = (e) => {
    let passwordError = "";

    if (password === e.target.value) {
      setCheckConfirmPassword(true);
      handleConfirmPassword(true);
    } else {
      passwordError = "كلمة المرور غير مطابقة";
      setCheckConfirmPassword(false);
      handleConfirmPassword(false);
    }
    setPasswordError(passwordError);
    setConfirmPasswordValue(e.target.value);
  };

  const handleStrongPassword = (e) => {
    let passwordError = "";
    let strongPasswordError = "";
    if (e.target.value.length < 8) {
      strongPasswordError = "كلمة المرور أقل من 8 أحرف";
    }
    if (confirmPassword === e.target.value) {
      setCheckConfirmPassword(true);
      handleConfirmPassword(true);
    } else {
      passwordError = "كلمة المرور غير مطابقة";
      setCheckConfirmPassword(false);
      handleConfirmPassword(false);
    }

    handlePassword(e);
    setStrongPasswordError(strongPasswordError);
    setPasswordError(passwordError);
  };

  return (
    <form>
      <div className="form-group">
        <label>البريد الإلكتروني</label>
        <CustomInput
          type="email"
          icon={<EmailIcon />}
          onChange={(e) => handleChange(e)}
          placeholder="أدخل بريدك الإلكتروني"
        />
        {
          <div className="error" style={{ color: "red" }}>
            {emailError}
          </div>
        }
      </div>

      <div className="form-group">
        <label>رقم الهاتف</label>
        <PhoneInput
          international
          value={phoneNumber}
          onChange={(val) => handleChangeNumber(val)}
          className="phoneNumberInput jeelPhoneNumberInput"
          required
          defaultCountry={country}
        />
        {
          <div className="error" style={{ color: "red" }}>
            {phoneError}
          </div>
        }
      </div>

      <div className="form-group">
        <label>كلمة المرور</label>
        <CustomInput
          type={showPassword ? "text" : "password"}
          icon={<PasswordIcon />}
          onChange={(e) => handleStrongPassword(e)}
          isPassword={true}
          togglePassword={togglePassword}
          placeholder="أنشئ كلمة مرور"
        />
        {strongPasswordError && (
          <div className="error" style={{ color: "red" }}>
            {strongPasswordError}
          </div>
        )}
      </div>

      <div className="form-group">
        <label>تأكيد كلمة المرور </label>
        <CustomInput
          type={showConfirmPassword ? "text" : "password"}
          icon={<PasswordIcon />}
          onChange={(e) => handleConfirmPasswordInput(e)}
          isPassword={true}
          togglePassword={toggleConfirmPassword}
          placeholder="أكد كلمة المرور"
        />
        {passwordError && (
          <div className="error" style={{ color: "red" }}>
            {passwordError}
          </div>
        )}
      </div>
      <button
        className="btn btn-auth"
        onClick={(e) => {
          e.preventDefault();
          handleRegisterSubmit();
        }}
        disabled={loading || confirmForm}
      >
        إنشاء حساب
        {loading && (
          <FontAwesomeIcon icon={faSpinner} className="faTimes fa-spin mr-2" />
        )}
      </button>
      <div className="auth-note text-center mt-3">
        هل لديك حساب بالفعل؟{" "}
        <button
          className="auth-link"
          onClick={() => {
            history.push("/login");
          }}
        >
          تسجيل الدخول
        </button>
      </div>
    </form>
  );
};

export default SignupForm;
