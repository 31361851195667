import {
    FETCH_ALL_PDF_BOOKS,
    ADD_PDF_BOOK,
    PUBLISH_PDF_BOOK,
    GET_PDF_BOOK_DATA,
    ERROR_LOADING_ALL_PDF_BOOKS,
    ERROR_LOADING_PDF_BOOK,
    EMPTY_PDF_BOOK,
    EMPTY_ALL_PDF_BOOKS,
    LOAD_PDF_BOOK,
    LOAD_ALL_PDF_BOOKS,
    ERROR_ADDING_PDF_BOOK,
    EDIT_PDF_BOOK_DATA,
    ERROR_EDITING_PDF_BOOK,
    DELETE_PDF_BOOK,
    ERROR_DELETING_PDF_BOOK,
    SETTING_PDF_BOOK_STATUS,
    ERROR_SETTING_PDF_BOOK_STATUS,
    ADD_PDF_BOOK_WITH_SENTFROM,
    ADD_PDF_BOOK_PAGE,
    ERROR_ADDING_PDF_BOOK_PAGE,
    UPDATE_PDF_BOOK_PAGE,
    ERROR_UPDATE_PDF_BOOK_PAGE,
    LOAD_PDF_BOOK_PAGE,
    ERROR_DELETING_PDF_BOOK_PAGE,
    DELETE_PDF_BOOK_PAGE,
    GET_ALL_PARENTS
} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},

    loading: '',

    error_adding_book: '',
    book_added_successfully: '',

    error_loading_all_books: '',
    error_loading_book: '',

    book_updated_successfully: '',
    error_updated_book: '',

    book_deleted_successfully: '',
    error_deleteing_book: '',

    book_status_updated_successfully: '',
    error_setting_status: '' 
    
    ,parent_items:[],
	pdf_get_all_parent_error:'',
	pdf_get_all_parent_successfully:'',
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_PDF_BOOK_WITH_SENTFROM:
            return {
                ...state,
                item: action.payload
            }
        case FETCH_ALL_PDF_BOOKS:
            return {
                ...state,
                items: action.payload,
                error_loading_all_books: '',
                loading: false,
                item: {}
            }

        case ADD_PDF_BOOK:
            return {
                ...state,
                item: action.payload,
                loading: false,
            }

        case PUBLISH_PDF_BOOK:
            return {
                ...state,
                item: action.payload,
                book_added_successfully: true,
                loading: false,
            }

        case GET_PDF_BOOK_DATA:
            return {
                ...state,
                item: action.payload,
                error_loading_book: '',
                loading: false
            }


        case ERROR_LOADING_ALL_PDF_BOOKS:
            return {
                ...state,
                error_loading_all_books: action.payload,
                loading: false
            }
        case ERROR_LOADING_PDF_BOOK:
            return {
                ...state,
                error_loading_book: action.payload,
                loading: false
            }

        case EMPTY_PDF_BOOK:
            return {
                ...state,
                error_adding_book: '',
                book_added_successfully: '',

                error_updated_book: '',
                book_updated_successfully: '',

                error_deleteing_book: '',
                book_deleted_successfully: '',

                book_status_updated_successfully: '',
                error_setting_status: '',

                error_loading_book: '',
                error_loading_all_books: '',

                // 
                // error_adding_book_page: '',
                // book_page_added_sucessfully: '',
                // updating_book_page_error: '',
                // updating_book_page_sucessfully: '',
                // deleting_book_page_sucessfully: '',
                // deleting_book_page_error: '',
                loading: false

            }
        case EMPTY_ALL_PDF_BOOKS:
            return {
                ...state,
                items: [],
                item: {},
            }

        case LOAD_PDF_BOOK:
            return {
                ...state,
                loading: true
            }


        case LOAD_ALL_PDF_BOOKS:
            return {
                ...state,
                loading: true
            }

        case ERROR_ADDING_PDF_BOOK:
            return {
                ...state,
                error_adding_book: action.payload,
                book_added_successfully: false,
                loading: false,
            }

        case EDIT_PDF_BOOK_DATA:
            return {
                ...state,
                item: action.payload,
                book_updated_successfully: true,
                loading: false,
            }

        case ERROR_EDITING_PDF_BOOK:
            return {
                ...state,
                error_updated_book: action.payload,
                book_updated_successfully: false,
                loading: false
            }

        case DELETE_PDF_BOOK:
            return {
                ...state,
                item: action.payload,
                book_deleted_successfully: true,
                loading: false,
            }
        case ERROR_DELETING_PDF_BOOK:
            return {
                ...state,
                error_deleteing_book: action.payload,
                book_deleted_successfully: false,
                loading: false,
            }

            case SETTING_PDF_BOOK_STATUS:
                return {
                    ...state,
                    book_status_updated_successfully: true,
                    item: action.payload,
                    loading: false
                }
            case ERROR_SETTING_PDF_BOOK_STATUS:
                return {
                    ...state,
                    book_status_updated_successfully: false,
                    error_setting_status: action.payload,
                    loading: false
                }
                
			//parent:
			case GET_ALL_PARENTS:
            	return {
                ...state,
                parent_items: action.payload,
                loading: false,
                pdf_get_all_parent_error: '',
                pdf_get_all_parent_successfully: true,
            }
                            
            // case ADD_BOOK_PAGE: // START PAGES
            //     return {
            //         ...state,
            //         addPageItem: action.payload,
            //         book_page_added_sucessfully: true,
            //         loadingPages: false
            //     }
            // case ERROR_ADDING_BOOK_PAGE:
            //     return {
            //         ...state,
            //         error_adding_book_page: action.payload,
            //         book_page_added_sucessfully: false,
            //         loadingPages: false
            //     }
            // case UPDATE_BOOK_PAGE:
            //     return {
            //         ...state,
            //         pageItemUpdated: action.payload,
            //         updating_book_page_sucessfully: true,
            //         updating_book_page_error: '',
            //         loadingPages: false
            //     }

            // case ERROR_UPDATE_BOOK_PAGE:
            //     return {
            //         ...state,
            //         updating_book_page_error: action.payload,
            //         loadingPages: false,
            //         updating_book_page_sucessfully: false
            //     }
            // case DELETE_BOOK_PAGE:
            //     return {
            //         ...state,
            //         pageItemDeleted: action.payload,
            //         deleting_book_page_sucessfully: true,
            //         loadingPages: false
            //     }
            // case ERROR_DELETING_BOOK_PAGE:
            //     return {
            //         ...state,
            //         deleting_book_page_error: action.payload,
            //         loadingPages: false
            //     }
            // case LOAD_BOOK_PAGE:
            //     return {
            //         ...state,
            //         loadingPages: true
            //     }

            default:
                return state    
        }
    
    }
    

