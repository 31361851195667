import React, { useEffect, useState } from "react";
import AuthLayout from "../layout/AuthLayout";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import SignupForm from "./SignupForm";
import {
  customerRegister,
  empty,
  customerSignIn,
} from "../../../../Actions/AuthActions";
import {
  signedUpCustomer,
  emptyCustomer,
  signedInCustomer,
} from "../../../../Actions/CustomerActions";
import { removeSuccessMessage } from "../../../../Actions/SuccessMessagesActions";
import {
  PORTAL_PRE_PAID,
  PORTAL_UPGRADE_SUBSCRIPTION,
} from "../../../../routes";
import { addCodeToRedux } from "../../../../Actions/PromoCodesActions";
import { PRE_PAID_CARD } from "../../constants";

const SignupPage = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [country, setCountry] = useState("EG");
  const [confirmForm, setConfirmForm] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [confirmPhoneNumber, setConfirmPhoneNumber] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);

  const { success, error, loginsucess, loginloading, paymentMethodName } =
    props;

  useEffect(() => {
    if (loginsucess) {
      let redirect =
        paymentMethodName === PRE_PAID_CARD
          ? PORTAL_PRE_PAID
          : PORTAL_UPGRADE_SUBSCRIPTION;
      history.push(redirect);
    }
  }, [loginsucess]);

  useEffect(() => {
    if (success === true && error === "") {
      setErrorMsg("");
      setErrorStatus(false);
      handleLoginSubmit();
    } else if (success === false && error !== "" && error !== false) {
      if (error.code === "auth/email-already-in-use") {
        setErrorMsg("البريد الإلكتروني المُدخل مسجل مسبقاً");
      } else {
        setErrorMsg("حدث خطأ ما، يرجي المحاولة مرة أخرى");
      }
      setErrorStatus(true);

      props.empty();
      props.emptyCustomer();
    }
  }, [success, error, error.code]);

  const handleData = () => {
    setErrorMsg("");
    setErrorStatus(false);
  };

  useEffect(() => {
    if (
      email.length > 0 &&
      password.length > 0 &&
      confirmPassword === true &&
      confirmPhoneNumber === true
    ) {
      setConfirmForm(false);
    } else {
      setConfirmForm(true);
    }
  }, [email, password, confirmPassword, confirmPhoneNumber]);

  const handleEmail = (e) => {
    handleData();
    const emailValue = e.target.value;
    setEmail(emailValue);
  };

  const handlePassword = (e) => {
    handleData();
    const passwordValue = e.target.value;
    setPassword(passwordValue);
  };

  const handlePhoneNumber = (val) => {
    handleData();
    const phoneNumberValue = val;
    setPhoneNumber(phoneNumberValue);
  };

  const handleConfirmPassword = (val) => {
    setConfirmPassword(val);
  };
  const handleConfirmPhoneNumber = (val) => {
    setConfirmPhoneNumber(val);
  };
  const handleRegisterSubmit = () => {
    let obj = {
      email,
      password,
      phoneNumber,
      code,
      country,
      confirmForm,
      confirmPassword,
      confirmPhoneNumber,
    };
    // call Register API
    props.signedUpCustomer();
    props.customerRegister(obj);
  };

  const handleLoginSubmit = () => {
    let obj = {
      email,
      password,
    };
    props.signedInCustomer();
    props.customerSignIn(obj);
  };

  return (
    <AuthLayout>
      <div className="auth-card">
        <div className="mb-3">
          <div className="auth-title">انشاء حساب</div>
          <div className="auth-desc mt-3">
            يرجى إدخال بيانات الاعتماد الخاصة بك لتسجيل الدخول.
          </div>
        </div>
        <p style={{ color: "red", textAlign: "right" }}>
          {errorStatus && errorMsg}
        </p>
        <div className="auth-form">
          <SignupForm
            handleEmail={handleEmail}
            handlePassword={handlePassword}
            handlePhoneNumber={handlePhoneNumber}
            handleConfirmPassword={handleConfirmPassword}
            handleConfirmPhoneNumber={handleConfirmPhoneNumber}
            handleRegisterSubmit={handleRegisterSubmit}
            loading={loginloading}
            confirmForm={confirmForm}
            phoneNumber={phoneNumber}
            country={country}
            confirmPassword={confirmPassword}
            password={password}
            email={email}
            setCountry={setCountry}
          />
        </div>
      </div>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => ({
  customer: state.customers.item,
  success: state.customers.adding_customer_sucessfully,
  loading: state.customers.loading,
  error: state.customers.adding_customer_error,
  loginsucess: state.authUser.success,
  loginerror: state.authUser.errorMsg,
  loginloading: state.authUser.loading,
  codeName: state.promoCodes.code_name,
  userCountry: state.countryName.country,
  successMessage: state.successMessages.item,
  paymentMethodName: state.paymentData.paymentMethod,
});

export default connect(mapStateToProps, {
  signedUpCustomer,
  customerRegister,
  removeSuccessMessage,
  empty,
  emptyCustomer,
  signedInCustomer,
  addCodeToRedux,
  customerSignIn,
})(SignupPage);
