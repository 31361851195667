import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const CustomInput = ({
  wrapperClassName = "",
  className = "",
  icon = null,
  type = "text",
  onChange,
  isPassword = false,
  togglePassword = undefined,
  placeholder = "",
}) => {
  return (
    <div
      className={`custom-auth-input-wrapper ${
        !!icon && "with-icon"
      } ${wrapperClassName}`}
    >
      <div className="input-icon">{icon}</div>

      <input
        placeholder={placeholder}
        className={`custom-auth-input ${className}`}
        type={type}
        onChange={onChange}
      />
      {isPassword && (
        <div className="password-icon">
          <FontAwesomeIcon
            onClick={togglePassword}
            icon={faEye}
            className={`faEye cusror-pointer ${
              isPassword && type == "password" ? "d-none" : "d-block"
            }`}
          />
          <FontAwesomeIcon
            onClick={togglePassword}
            icon={faEyeSlash}
            className={`faEyeSlash cusror-pointer ${
              isPassword && type == "password" ? "d-block" : "d-none"
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default CustomInput;
